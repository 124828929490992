/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Grid } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from "common/history";
import * as appActions from '../../core/app.store';

import CursorPointerIcon from '../../assets/icon/cursor-pointer.svg';
import ReflectAside from './components/aside/aside.view';
import ReflectHeader from './components/header/reflect-header.view';
import ReflectPageHeader from './components/header/reflect-page-header.view';
import {
  CardHorizontal,
  CardVertival,
} from './components/reflect-card/reflect-card.view';
import { ReflectHomePageSkeleton } from './components/skeleton/skeleton.view';
import { useGetReflectListHomePage, useGetReflects } from './hooks';
import './reflects.scss';

function Reflect(props) {
  const { reflectByCategory, isLoading } = useGetReflectListHomePage();
  const { listReflects } = useGetReflects();

  return (
    <div style={{ position: 'relative' }}>
      <ReflectPageHeader title='Phản ánh hiện trường' />
      <a href='/phan-anh-kien-nghi'>
        <div className='add-reflect'>
          <img src={CursorPointerIcon} alt='' className='icon' />
          <div className='text'>Gửi phản ánh</div>
        </div>
      </a>
 

      <div className='container reflect mt-4'>
        <Grid container className='row' spacing={3}>
          <Grid item xs={12} lg={8} className='reflect-category'>
            {isLoading ? (
              <>
                <ReflectHomePageSkeleton />
                <ReflectHomePageSkeleton />
                <ReflectHomePageSkeleton />
                <ReflectHomePageSkeleton />
                <ReflectHomePageSkeleton />
              </>
            ) : (
              <>
                {reflectByCategory?.map((category) => {
                  return (
                    category?.reflectTheScenes?.length > 0 && (
                      <>
                        <ReflectHeader
                          title={category?.categoryName}
                          urlReadMore={`/phan-anh-hien-truong/chuyen-muc/${category?.categoryId}`}
                        />
                        <Grid container spacing={2} className='d-sm-none mt-2'>
                          {category?.reflectTheScenes?.map((item, i) => (
                            <Grid item xs={12} sm={6} key={i}>
                              {i < 1 ? (
                                <CardVertival reflect={item} />
                              ) : (
                                <CardHorizontal reflect={item} />
                              )}
                            </Grid>
                          ))}
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          className='d-none d-sm-flex mt-2'
                        >
                          {category?.reflectTheScenes?.map((item, i) => (
                            <Grid item xs={12} sm={6} key={i}>
                              {i < 2 ? (
                                <CardVertival reflect={item} />
                              ) : (
                                <CardHorizontal reflect={item} />
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    )
                  );
                })}
              </>
            )}
          </Grid>
          <Grid
            item
            xs={4}
            className='d-none d-lg-block'
            style={{ marginTop: '50px' }}
          >
            <ReflectAside listReflects={listReflects} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Reflect);
