/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Grid } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { bindActionCreators } from 'redux';

import * as appActions from '../../../core/app.store';

// import Pagination from '../../../components/pagination/pagination.view';
import Pagination from '../components/pagination/pagination.view';

import ReflectAdise from '../components/aside/aside.view';
import ReflectPageHeader from '../components/header/reflect-page-header.view';
import { ReflectByCategorySkeleton } from '../components/skeleton/skeleton.view';
import { CardHorizontal } from '../components/reflect-card/reflect-card.view';
import {
  useGetCategories,
  useGetReflects,
  useGetReflectsByCategoryId,
} from '../hooks';
import './reflects-by-category.scss';

function Reflect() {
  const { categoryInfo } = useParams();
  const re = /^(\d+)-?[a-z0-9-]*$/g;
  const categoryId = re.exec(categoryInfo)?.[1] || null;
  const { listReflects, total, setPage, isLoading, page, totalPage } =
    useGetReflectsByCategoryId(categoryId);

  const { listCategories } = useGetCategories();
  const { listReflects: listReflectsAside, isLoading: isLoadingAside } =
    useGetReflectsByCategoryId(categoryId);

  const currentCategory = listCategories?.find(
    (category) => parseInt(category?.id) === parseInt(categoryId)
  );

  return (
    <>
      <ReflectPageHeader title={currentCategory?.name} />
      <div className='container reflect mt-4 mb-4'>
        <Grid container className='row ' spacing={3}>
          <Grid item xs={12} md={8} sm={12} className='reflect-category'>
            {isLoading ? (
              <ReflectByCategorySkeleton />
            ) : (
              <>
                <Grid container spacing={2} className=''>
                  {listReflects?.map((item, i) => (
                    <Grid item xs={12} sm={6} lg={6} key={i}>
                      <CardHorizontal reflect={item} />
                    </Grid>
                  ))}
                </Grid>
                <div className='mt-4'>
                  <Pagination
                    className='mt-4'
                    // totalItems={total}
                    // currentIndex={page}
                    // pageSize={10}
                    // onClick={(page) => setPage(page)}
                    // pageTotal={totalPage}
                    page={page}
                    onChange={(e, page) => page && setPage(page)}
                    count={totalPage}
                  />
                </div>
              </>
            )}
          </Grid>

          <Grid item xs={12} md={4} sm={12} className=''>
            <ReflectAdise
              listReflects={listReflectsAside}
              isLoading={isLoadingAside}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Reflect);
