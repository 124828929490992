import React from "react";
import "./layout.scss";

export default class LayoutUserDesktopView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyWebStyle: null,
    };
  }

  render() {
    return (
      <div className="layout-user-container">
        <div>{this.props.children}</div>
      </div>
    );
  }
}
