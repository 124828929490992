import React from 'react';
import CommentItem from './comment-item.view';
import { Pagination } from '@material-ui/lab';
import CommentForm from './comment-form.view';
import { useGetListComment } from '../../hooks';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './comments.scss';

const ReflectComment = ({
  reflectId,
  className = '',
  totalComment,
  isLoggedIn,
}) => {
  const {
    comments,
    pageIndex,
    totalCommentPage,
    refreshComment,
    setPageIndex,
    totalItemOriginalCount,
  } = useGetListComment(reflectId);

  return (
    <div className={'paper reflect-comment p-2 p-lg-4 ' + `${className}`}>
      <h4>Bình luận: {totalItemOriginalCount}</h4>
      {isLoggedIn && (
        <>
          <CommentForm reflectId={reflectId} onSuccess={refreshComment} />
        </>
      )}
      <div>
        {comments?.map((item, i) => (
          <CommentItem
            comment={item}
            isParent={true}
            key={i}
            refreshComment={refreshComment}
          />
        ))}
      </div>
      {!!totalComment && (
        <Pagination
          className='reflect-pagination mt-4'
          page={pageIndex}
          count={totalCommentPage}
          onChange={(e, page) => page && setPageIndex(page)}
          variant='outlined'
          shape='rounded'
        />
      )}
    </div>
  );
};

// export default ReflectComment;

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
  isLoggedIn: state.account.isLoggedIn,
  user: state.account.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReflectComment);
