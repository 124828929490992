import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import moment from "moment";

const service = new Service();

export const GetSlideShow = () => {
  return (dispatch) => {
    return service
      .get(ApiUrl.SlideShow)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const GetAllPlanningByTypeId = (planningTypeId) => {
  return (dispatch) => {
    const params = new URLSearchParams();
    planningTypeId && params.append("planningTypeId", planningTypeId);
    return service
      .get(ApiUrl.GetAllPlanningByTypeId, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const GetLatLngPlace = (adress) => {
  const params = new URLSearchParams();
  params.append("key", "ac543d442b430dcfbe8ed6d89fe5e0d8");
  adress && params.append("text", adress);
  return service
  .get("https://api.map4d.vn/sdk/autosuggest", params)
  .then((res) => {
    return res;
  })
  .catch((err) => {
    throw err;
  });
}

export const GetWgs84ToVn2000 = (longitude, latitude) => {
  const params = new URLSearchParams();
  longitude && params.append("longitude", longitude);
  latitude && params.append("latitude", latitude);
  return service
  .get(ApiUrl.Wgs84ToVn2000, params)
  .then((res) => {
    return res;
  })
  .catch((err) => {
    throw err;
  });
};

export const GetVn2000ToWgs84 = (x, y) => {
    const params = new URLSearchParams();
    x && params.append("x", x);
    y && params.append("y", y);
    return service
    .get(ApiUrl.Vn2000ToWgs84, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetPlanningSearchByIdName = (
  PlanningTypeById,
  pageIndex = 1,
  planningId,
  name,
  pageSize = 6,
  district
) => {
  const params = new URLSearchParams();
  PlanningTypeById && params.append("planningTypeId", PlanningTypeById);
  planningId && params.append("planingId", planningId);
  name && params.append("name", name);
  district && params.append("district", district);
  params.append("pageIndex", pageIndex);
  params.append("pageSize", pageSize);
  return service
    .get(ApiUrl.PlanningSearchByIdName, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const GetPlanningSearchByIdNameHome = (
  PlanningTypeById,
  pageIndex = 1,
  pageSize = 4
) => {
  const params = new URLSearchParams();
  PlanningTypeById && params.append("planningTypeId", PlanningTypeById);
  params.append("pageIndex", pageIndex);
  params.append("pageSize", pageSize);
  return service
    .get(ApiUrl.PlanningSearchByIdName, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const GetPlanningSearchByIdNameDistrict = (
  planningTypeId,
  name,
  district,
  planningUnitId,
  yearSearch,
  approvalAgencyId,
  numberOfDecisions,
  constructionGroup,
  pageIndex,
  pageSize,
) => {
  const params = new URLSearchParams();
  planningTypeId && params.append("planningTypeId", planningTypeId);
  name && params.append("name", name);
  district && params.append("district", district);
  planningUnitId && params.append("planningUnitId", planningUnitId);
  yearSearch && params.append("year", yearSearch);
  approvalAgencyId && params.append("approvalAgencyId", approvalAgencyId);
  numberOfDecisions && params.append("numberOfDecisions", numberOfDecisions);
  constructionGroup && params.append("constructionGroup", constructionGroup);
  pageIndex && params.append("pageIndex", pageIndex);
  pageSize && params.append("pageSize", pageSize);
  return service
    .get(ApiUrl.PlanningSearchByIdName, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const SearchPlanningByLatLng = (longtitude, latitude) => {
  const params = new URLSearchParams();
  longtitude && params.append("longtitude", longtitude);
  latitude && params.append("latitude", latitude);
  return service
  .get(ApiUrl.SearchPlanningByLatLng, params)
  .then((res) => {
    return res;
  })
  .catch((err) => {
    throw err;
  });
};