import React from "react";
import { Link } from "react-router-dom"
import { Paper } from "@material-ui/core";
import {
  PlaceOutlined,
  QueryBuilderOutlined,
  ChatBubbleOutlined,
} from "@material-ui/icons";
import "./header.scss";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/free-solid-svg-icons";
export const ReflectPageHeader = ({ title, breadcumb, className = "" }) => {
  return (
    <>
      <div className={"reflect-page-header" + ` ${className}`}>
        <div className="reflect-page-header__content">
          <h1>{title}</h1>
          <div className="reflect-page-header__breadcumb">
            {breadcumb?.map((item, i) => <Link to={item?.url || "#"} key={i} >{item?.name || ""}</Link>)}
          </div>
        </div>
      </div>
      {/* <div className="map-paht mt-4 mb-4 col-lg-4">
        <Link to="/ban-do-phan-anh-hien-truong">
          <Button
            variant="contained"
            color="primary"
            mini={true}
          >
            Bản đồ &ensp;
            <FontAwesomeIcon icon={faMap} />
          </Button>
        </Link>
      </div> */}
    </>
  );
};

export default ReflectPageHeader;
