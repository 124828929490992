import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import './please-consult-the-community.scss'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../core/app.store";

import * as consultantCommunityActions from "../../../redux/store/consultant-community/consultant-community.store";
import { APIUrlDefault } from "../../../utils/configuration";
import dateformat from "dateformat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

function PctCommunityHome(props) {
    const { showLoading } = props;

    //da xin y kien
    const [resultModel, setResultModel] = useState([]);
    const [firstResult, setFirstResult] = useState({})
    const [paging, setPaging] = useState();
    const [currentPage, setCurrentPage] = useState();
    const pageSize = 4;
    //dang xin y kien
    const [consulting, setConsulting] = useState([]);

    useEffect(() => {
        onGetData();
    }, []);

    const onGetData = () => {
        showLoading(true);
        Promise.all([

            onGetListExpiredForm({
                pageIndex: 1,
                pageSize: pageSize,
            }),
            onGetListConsultantCommunity({
                pageIndex: 1,
                pageSize: 2,
            })
        ])
            .then((res) => {
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };
    const onGetListConsultantCommunity = (data) => {
        return new Promise((resolve, reject) => {
            consultantCommunityActions.GetListConsultantCommunity(data).then(
                (res) => {
                    setConsulting(
                        res &&
                            res.content &&
                            res.content.items &&
                            res.content.items.length > 0
                            ? res.content.items
                            : []
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    };
    const onGetListExpiredForm = (data) => {
        return new Promise((resolve, reject) => {
            consultantCommunityActions.GetListResultConsultantCommunity(data).then(
                (res) => {
                    setResultModel(
                        res &&
                            res.content &&
                            res.content.items &&
                            res.content.items.length > 0
                            ? res.content.items
                            : []
                    );
                    setPaging(res.content);
                    setCurrentPage(res.content.pageIndex);
                    setFirstResult(res.content.items[0])
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    };

    const getNextPage = () => {
        let nextPage = 1;
        if (currentPage > 0) {
            nextPage = currentPage + 1;
        }
        onGetListExpiredForm({
            pageIndex: nextPage,
            pageSize: pageSize
        })
    }

    const getPreviousPage = () => {
        let prePage = 1;
        if (currentPage > 1) {
            prePage = currentPage - 1;
        }
        onGetListExpiredForm({
            pageIndex: prePage,
            pageSize: pageSize
        })
    }

    return (
        <div>
            <section className="consult">
                <div className="container">
                    <div className="title__consult">
                        <div className="row">
                            <div className="col">
                                <Link to="/y-kien-cong-dong"><h3>Xin ý kiến cộng đồng</h3></Link>
                            </div>
                        </div>
                    </div>
                    {consulting && consulting.length > 0 ? (
                        <div className="row">
                            <div className="col-lg-6 mb-3">
                                <div className="title__">
                                    <Link to="">QUY HOẠCH ĐANG XIN Ý KIẾN</Link>
                                </div>
                                <div>
                                    {consulting.map((item, index) => (
                                        <div key={index}>
                                            <div className="row">
                                                <Link to={"/xin-y-kien/" + item.id} className="col-lg-6">
                                                    <img
                                                        className="consult__img"
                                                        src={APIUrlDefault + item.avatar}
                                                        onError={(e) =>
                                                            (e.target.src = require("../../../assets/image/noImage.png"))
                                                        }
                                                        alt={item.planningName}
                                                    />
                                                </Link>
                                                <div className="col-lg-6">
                                                    <Link to={"/xin-y-kien/" + item.id} className="consult__name">
                                                        {item.planningName}
                                                    </Link>
                                                    <div className="new-date">
                                                        {dateformat(item.startTime, "dd/mm/yyyy")} - {dateformat(item.endTime, "dd/mm/yyyy")}
                                                    </div>
                                                    <Link to={"/xin-y-kien/" + item.id} className="consulting__title">
                                                        {item.title}
                                                    </Link>
                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="title__ row">
                                    <Link to="/y-kien-tong-hop" className="col">KẾT QUẢ XIN Ý KIẾN</Link>
                                    <div className="new-nav col">
                                        {
                                            paging && paging.hasPreviousPage && (
                                                <a className="mr-3" onClick={getPreviousPage}>
                                                    <FontAwesomeIcon icon={faArrowLeft} className="icon-link" />
                                                </a>
                                            )
                                        }

                                        <b>{paging && paging.pageIndex} / {paging && paging.pageCount}</b>

                                        {paging && paging.hasNextPage && (
                                            <a className="ml-3" onClick={getNextPage}>
                                                <FontAwesomeIcon icon={faArrowRight} className="icon-link" />
                                            </a>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    {resultModel && resultModel.length > 0 ? (
                                        // resultModel.slice(1).map((item, index) => (
                                            resultModel.map((item, index) => (
                                            <div key={index} className="new-item">
                                                <div className="row">
                                                    <div className='col-4'>
                                                        <Link to={"/y-kien-tong-hop?search=" + item.id} >
                                                            <div className="new-image consult_item">
                                                                <img
                                                                    src={APIUrlDefault + item.avatar}
                                                                    onError={(e) =>
                                                                        (e.target.src = require("../../../assets/image/noImage.png"))
                                                                    }
                                                                    alt={item.planningName}
                                                                />
                                                            </div>
                                                        </Link>
                                                    </div>

                                                    <div className="col-8">
                                                        <Link to={"/y-kien-tong-hop?search=" + item.id} className="consult__name">{item.planningName}</Link>
                                                        <div className="new-date">{dateformat(item.publishDate, "dd/mm/yyyy")}</div>
                                                    </div>
                                                </div>

                                            </div>
                                        ))
                                    ) : (<div className="new-item">Chưa có dữ liệu để hiển thị!</div>)}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="title__ row">
                                <Link to="/y-kien-tong-hop" className="col">KẾT QUẢ XIN Ý KIẾN</Link>
                                <div className="new-nav col">
                                    {
                                        paging && paging.hasPreviousPage && (
                                            <a className="mr-3" onClick={getPreviousPage}>
                                                <FontAwesomeIcon icon={faArrowLeft} className="icon-link" />
                                            </a>
                                        )
                                    }

                                    <b>{paging && paging.pageIndex} / {paging && paging.pageCount}</b>

                                    {paging && paging.hasNextPage && (
                                        <a className="ml-3" onClick={getNextPage}>
                                            <FontAwesomeIcon icon={faArrowRight} className="icon-link" />
                                        </a>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 mb-4" >
                                    <Link to={"/y-kien-tong-hop?search=" + firstResult.id}>
                                        <img
                                            className="consult__img"
                                            src={APIUrlDefault + firstResult.avatar}
                                            onError={(e) =>
                                                (e.target.src = require("../../../assets/image/noImage.png"))
                                            }
                                            alt={firstResult.planningName}
                                        />
                                        <p className="consult__name">{firstResult.planningName}</p>
                                    </Link>
                                    <div className="new-date">{dateformat(firstResult.publishDate, "dd/mm/yyyy")}</div>
                                    <Link to={"/y-kien-tong-hop?search=" + firstResult.id}>{firstResult.title}</Link>
                                </div>
                                <div className="col">
                                    <div className="row">
                                        {resultModel && resultModel.length > 0 ? (
                                            // resultModel.slice(1).map((item, index) => (
                                                resultModel.map((item, index) => (
                                                <div key={index} className="new-item ">
                                                    <div className="row consult_item">
                                                        <Link to={"/y-kien-tong-hop?search=" + item.id} className='col-5'>
                                                            <div className="new-image ">
                                                                <img
                                                                    src={APIUrlDefault + item.avatar}
                                                                    onError={(e) =>
                                                                        (e.target.src = require("../../../assets/image/noImage.png"))
                                                                    }
                                                                    alt={item.planningName}
                                                                />
                                                            </div>
                                                        </Link>
                                                        <div className="col-7">
                                                            <Link to={"/y-kien-tong-hop?search=" + item.id} className="consult__name">{item.planningName}</Link>
                                                            <div className="new-date">{dateformat(item.publishDate, "dd/mm/yyyy")}</div>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))
                                        ) : (<div className="new-item">Chưa có dữ liệu để hiển thị!</div>)}
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}

                </div>
            </section>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
    listPolygon: state.mapData.listPolygon
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default (connect(mapStateToProps, mapDispatchToProps)(PctCommunityHome));
