import Service from "../../../api/api-service-custom";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetAllComment = (paramObj = {}) => {
  const params = new URLSearchParams();
  if (typeof paramObj === 'object') {
    for (let key in paramObj) {
      params.append(key, paramObj[key])
    }
  }
  return service
    .get(ApiUrl.GetAllComment, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const CreateComment = (body = {}) => {
  const formData = new FormData();
  if (typeof body === 'object') {
    let fileIndex = 0;
    for (let key in body) {
      if (Array.isArray(body[key])) {
        const arr = body[key]?.filter((item) => item !== undefined);
        if (key === 'files') {
          for (let i = 0; i < arr.length; i++) {
            if (!arr[i].fileId) {
              formData.append(`fileInfos[${fileIndex}].Action`, 'insert');
              formData.append(`fileInfos[${fileIndex}].File`, arr[i]);
              fileIndex++;
            }
          }
        } else if (key === 'deletedFiles') {
          for (let i = 0; i < arr.length; i++) {
            formData.append(`fileInfos[${fileIndex}].Action`, 'delete');
            formData.append(`fileInfos[${fileIndex}].Id`, arr[i].fileId);
            formData.append(`fileInfos[${fileIndex}].url`, arr[i].filePath);
            fileIndex++;
          }
        } else {
          for (let element of arr) {
            formData.append(key, element);
          }
        }
      } else {
        formData.append(key, body[key]);
      }
    }
  }
  return service
    .post(ApiUrl.CreateComment, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const UpdateComment = (body = {}) => {
  const formData = new FormData();
  if (typeof body === 'object') {
    for (let key in body) {
      formData.append(key, body[key])
    }
  }
  return service
    .put(ApiUrl.UpdateComment, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const DeleteComment = (id = '') => {
  return service
    .put(ApiUrl.DeleteComment+ '?id=' + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
