import moment from 'moment';

export const URGENT_OPTIONS = [
  {
    label: 'Phản ánh khẩn',
    key: true,
  },
  {
    label: 'Phản ánh bình thường',
    key: false,
  },
];

export const dateVNFormat = (date, format = 'DD/MM/YYYY') => {
  if (date) return moment(date).format(format);
  return '';
};

export const stripHtml = (html) => {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};
