import React, { useState, useEffect, useRef } from 'react'
import { useGetCategories } from '../hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Checkbox } from '@material-ui/core';
import OlMap from "ol/Map";
import OlView from "ol/View";
import OlOverlay from 'ol/Overlay';
import OlLayerTile from "ol/layer/Tile";
import OlSourceOSM from "ol/source/OSM";
import OlSourceXYZ from "ol/source/XYZ";
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature'
import { fromLonLat, transform, toLonLat } from "ol/proj";
import { Icon, Style } from 'ol/style';
import VectorSource from 'ol/source/Vector';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import {
  Categories,
  DeleteReflect,
  ReflectByCategories,
  ReflectDetail,
} from '../../../redux/store/category/category.store';
import { CardVertival } from '../components/reflect-card/reflect-card.view';
import './reflect-map.scss'

const mapAction = {
  ShowReflectInfo: 'ShowReflectInfo',
}

const markerLayer = new VectorLayer({
  className: 'marker',
  style: function (feature) {
    return feature.get('style');
  },
})

const overlay = new OlOverlay({
  autoPan: {
    animation: {
      duration: 250,
    },
  },
})

const ReflectMap = () => {
  const { data: listCategories } = useGetCategories()
  const [isOpenMenu, setOpenMenu] = useState(false)
  const [categoryId, setCategoryId] = useState(null)
  const [reflects, setReflects] = useState([])
  const [reflect, setReflect] = useState(null)
  const [mapState, setMapState] = useState({ center: fromLonLat([104, 21]), zoom: 10 })
  const popupRef = useRef(null)
  const [olMap, setOlMap] = useState(new OlMap({
    target: null,
    layers: [
      new OlLayerTile({
        // source: new OlSourceOSM(),
        source: new OlSourceXYZ({
          url: 'https://mt0.google.com/vt/lyrs=y&hl=vi&x={x}&y={y}&z={z}'
        })
      }),
      markerLayer,
    ],
    view: new OlView({
      center: mapState.center,
      zoom: mapState.zoom
    }),
  }))

  const updateMap = () => {
    olMap.getView().setCenter(mapState?.center);
    olMap.getView().setZoom(olMap.getView().getZoom());
  }

  useEffect(() => {
    const handleGetReflects = async () => {
      try {
        const reflectRes = await ReflectByCategories(
          `CategoriesId=${categoryId}&IsDelete=false&Sorting=createdDate desc`
        )
        setReflects(reflectRes?.content?.items)
      } catch (error) {
        console.log('ReflectMap.handleGetReflects err', error)
      }
    }
    if (categoryId) {
      handleGetReflects()
    }
  }, [categoryId])

  useEffect(() => {
    const showReflectMarker = () => {
      const features = []
      for (let reflect of reflects) {
        const iconStyle = new Style({
          image: new Icon({
            anchor: [0.5, 1],
            src: '/assets/reflect/location.png',
            scale: 0.03,
          }),
        });

        const point = new Point(fromLonLat([+reflect?.longitude, +reflect?.latitude]))

        const iconFeature = new Feature(point);
        iconFeature.set('style', iconStyle);
        iconFeature.set('action', mapAction.ShowReflectInfo);
        iconFeature.set('data', reflect);

        features.push(iconFeature)
      }

      markerLayer.setSource(new VectorSource({ features }))

    }
    showReflectMarker()
  }, [reflects])

  useEffect(() => {
    setMapState({ center: fromLonLat([104, 21]), zoom: 10 });
  }, [])

  useEffect(() => {
    updateMap()
  }, [mapState])

  useEffect(() => {
    olMap.setTarget("reflect-map-target");
    overlay.setElement(popupRef?.current)
    olMap.addOverlay(overlay)
    olMap.on("moveend", () => {
      let center = olMap.getView().getCenter();
      let zoom = olMap.getView().getZoom();
      setMapState({ center, zoom });
    });
    olMap.on('click', (e) => {
      const coordinate = e.coordinate;
      const feature = olMap.forEachFeatureAtPixel(e.pixel, function (feature) {
        return feature;
      });
      overlay.getElement().style.display = 'none'
      if (!feature) {
        return
      }
      const { action, data } = feature?.values_
      console.log('on click map', feature?.values_)
      if (action === mapAction.ShowReflectInfo) {
        overlay.getElement().style.display = 'block'
        overlay.setPosition(coordinate);
        setReflect(data)
        return
      }
    })
    olMap.on('pointermove', function (e) {
      const pixel = olMap.getEventPixel(e.originalEvent);
      const hit = olMap.hasFeatureAtPixel(pixel)
      // if (olMap?.getTarget()?.style?) {
      //   olMap.getTarget().style.cursor = hit ? 'pointer' : '';
      // }
    });
  }, [])

  return <div id="reflect-map">
    <div id="reflect-map-target"></div>
    <div ref={popupRef} id="popup" class="ol-popup">
      <CardVertival reflect={reflect} isNewTab className="p-0" />
      <span 
        id="popup-closer" 
        class="ol-popup-closer" 
        onClick={() => {
          overlay.getElement().style.display = 'none'
        }}
      />
      <div id="popup-content"></div>
    </div>
    <div className="toggle-list" onClick={() => setOpenMenu(true)}>{'>'}</div>
    <div className={`menu ${isOpenMenu ? 'active' : ''}`}>
      <div className='menu__header' onClick={() => setOpenMenu(false)}>
        <p>Thông tin phản ánh</p>
        X
      </div>
      <div className="menu__search">
        <input placeholder='Tìm kiếm địa điểm' />
        <FontAwesomeIcon icon={faSearch} />
      </div>
      <h3>Lĩnh vực</h3>
      <div className="list-categories">
        {listCategories?.map((item, i) => (
          <div
            key={i}
            onClick={() => {
              if (item?.id === categoryId) {
                setCategoryId(null)
              } else {
                setCategoryId(item?.id)
              }
            }}
          >
            <Checkbox size="small" checked={item?.id === categoryId} />
            <p>{item?.name}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
}

export default ReflectMap
