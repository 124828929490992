import Service from '../../../api/api-service-custom';
import { ApiUrl } from '../../../api/api-url';

const service = new Service();

export const CreateLevel = (data = {}) => {
  return service
    .post(ApiUrl.CreateLevel, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const UpdateLevel = (data = {}) => {
  return service
    .put(ApiUrl.UpdateLevel, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
