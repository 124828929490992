import React, { useEffect, useState, useContext } from "react";

const GetIsHideFilterBoxContext = React.createContext();
const SetIsHideFilterBoxContext = React.createContext();

export function IsHideFilterBoxProvider({children}) {
    const [isHideFilterBox, setIsHideFilterBox] = useState(false);

    return (
        <GetIsHideFilterBoxContext.Provider value={isHideFilterBox}>
            <SetIsHideFilterBoxContext.Provider value={setIsHideFilterBox}>
                {children}
            </SetIsHideFilterBoxContext.Provider>
        </GetIsHideFilterBoxContext.Provider>
    )
}

export function useGetIsHideFilterBox() {
    return useContext(GetIsHideFilterBoxContext);
}

export function useSetIsHideFilterBox() {
    return useContext(SetIsHideFilterBoxContext);
}