import React, { useState } from 'react';
import CommentForm from './comment-form.view';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { DeleteComment } from '../../../../redux/store/reflect/reflect-comments.store';
import moment from 'moment';
import 'moment/locale/vi';

import './comments.scss';
import { BASE_IMAGE } from '../reflect-card/reflect-card.view';
import { api } from 'api/api-config'
import GridView from './gird.view'

const ReflectCommentItem = ({
  comment,
  className = '',
  isParent = false,
  refreshComment,
  user,
  isLoggedIn,
}) => {
  const [isOpenCommentForm, setOpenCommentForm] = useState(false);
  const [maxShowReply, setMaxShowReply] = useState(2);
  const [isShowMoreContent, setShowMoreContent] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  const handleDeleteComment = async () => {
    try {
      await DeleteComment(idToDelete);
      setIdToDelete(null);
      refreshComment();
    } catch (error) {}
  };

  if (isEditMode) {
    return (
      <CommentForm
        reflectId={comment?.reflectionId}
        comment={comment}
        onSuccess={() => {
          setEditMode(false);
          refreshComment();
        }}
        onClose={() => setEditMode(false)}
      />
    );
  }

  const cutContent = comment?.content?.substring(0, 200);

  return (
    <div className={'comment-item mt-4 ' + `${className}`}>
      <div className='comment-item__avatar'>
        <img
          src={
            comment?.user?.urlAvatar
              ? api + comment?.user?.urlAvatar
              : '/assets/reflect/default-avatar.png'
          }
          alt=''
        />
      </div>
      <div className='comment-item__body'>
        <div className='comment-item__content'>
          <p className='comment-item__author'>{comment?.userComment}</p>
          {/* <p>{comment?.content}</p> */}
          <p>
            {isShowMoreContent ? comment?.content : cutContent}
            {!isShowMoreContent && comment?.content !== cutContent && '... '}
            {!isShowMoreContent && comment?.content !== cutContent && (
              <span
                className='read-more'
                onClick={() => setShowMoreContent(true)}
              >
                Xem thêm
              </span>
            )}
            {isShowMoreContent && comment?.content !== cutContent && (
              <span
                className='read-more'
                onClick={() => setShowMoreContent(false)}
              >
                {' '}
                Ẩn bớt
              </span>
            )}
          </p>
          <GridView files={comment?.commentFilesViewDtos} />
        </div>
        <div className='comment-item__action'>
          {moment(comment?.createdDate)
            .locale('vi')
            .startOf('seconds')
            .fromNow()
            .replace('giây tới', 'giây trước')}
          &nbsp;&nbsp;&nbsp;&nbsp;
          {isLoggedIn && isParent && (
            <span onClick={() => setOpenCommentForm(true)}>Trả lời</span>
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;
          {comment?.createdBy === user?.email && (
            <span onClick={() => setEditMode(true)}>Sửa</span>
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;
          {comment?.createdBy === user?.email && (
            <span onClick={() => setIdToDelete(comment?.id)}>Xóa</span>
          )}
        </div>
        {isOpenCommentForm && (
          <CommentForm
            reflectId={comment?.reflectionId}
            parentId={comment?.id}
            onSuccess={() => {
              setOpenCommentForm(false);
              refreshComment();
            }}
            onClose={() => setOpenCommentForm(false)}
          />
        )}
        {comment?.childrenComments?.length > 0 && (
          <div className='comment-item__child'>
            {comment?.childrenComments
              ?.slice(0, maxShowReply)
              ?.map((item, i) => (
                <ReflectCommentItem
                  comment={item}
                  refreshComment={refreshComment}
                  user={user}
                  key={i}
                />
              ))}
          </div>
        )}
        <div className='comment-item__show-more'>
          {maxShowReply > 2 && (
            <div
              onClick={() => setMaxShowReply((state) => Math.max(2, state - 5))}
            >
              Ẩn bớt {maxShowReply < comment?.childrenComments?.length && '|'}{' '}
            </div>
          )}

          {maxShowReply < comment?.childrenComments?.length && (
            <div onClick={() => setMaxShowReply((state) => state + 5)}>
              Xem thêm
            </div>
          )}
        </div>
      </div>
      <Dialog open={!!idToDelete} onClose={() => setIdToDelete(null)}>
        <DialogTitle id='alert-dialog-title'>Xóa bình luận</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Bạn có muốn xóa bình luận này không
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIdToDelete(null)} color='primary'>
            Không
          </Button>
          <Button onClick={handleDeleteComment} color='primary' autoFocus>
            Xóa
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

// export default ReflectCommentItem

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
  isLoggedIn: state.account.isLoggedIn,
  user: state.account.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReflectCommentItem);
