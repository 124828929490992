/* eslint-disable jsx-a11y/alt-text */
import { PlaceOutlined, QueryBuilderOutlined } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { dateVNFormat, URGENT_OPTIONS } from '../../../../utils';
import { isImage, isVideo, isDocument } from 'utils/file';
import { api, apiCustom } from 'api/api-config';
import './reflect-card.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
export const BASE_IMAGE = apiCustom + '/';

export const CardVertival = ({ reflect, isNewTab, className = '' }) => {
  let IsUrgentHandling = URGENT_OPTIONS.find(
    (urgent) => urgent?.key === reflect?.isUrgentHandling
  );
  if (!IsUrgentHandling) {
    IsUrgentHandling = URGENT_OPTIONS?.[1];
  }

  let reflectImage = '/assets/reflect/test-thumb.png';
  if (reflect?.image) {
    reflectImage = BASE_IMAGE + reflect?.image;
  }
  // if (
  //   reflect?.reflectionFilesViewDtos?.filter((item) =>
  //     isImage(item?.fileName)
  //   )?.[0]?.filePath
  // ) {
  //   reflectImage =
  //     BASE_IMAGE +
  //     reflect?.reflectionFilesViewDtos?.filter((item) =>
  //       isImage(item?.fileName)
  //     )?.[0]?.filePath;
  // }

  return (
    <Link
      to={`/phan-anh-hien-truong/phan-anh/${reflect?.id}`}
      className={`reflect-card reflect-card__vertical ${className}`}
      target={isNewTab && '_blank'}
    >
      <LazyLoadImage src={reflectImage} alt={reflect?.title}></LazyLoadImage>
      <div className='card-content'>
        <div
          className={`card-content__type ${
            reflect?.isUrgentHandling
              ? 'card-content--red'
              : 'card-content--blue'
          }`}
        >
          {IsUrgentHandling?.label}
        </div>
        <div className='card-content__placement'>
          <PlaceOutlined />
          {reflect?.detectionPosition}
        </div>
        <div className='card-content__title'>{reflect?.title}</div>
        <div className='card-content__time'>
          <QueryBuilderOutlined />
          {dateVNFormat(reflect?.createdDate, 'DD/MM/YYYY hh:mm')}&nbsp;
        </div>
        <div
          className='card-content__description'
          dangerouslySetInnerHTML={{ __html: reflect?.content }}
        />
      </div>
    </Link>
  );
};

export const CardHorizontal = ({ reflect, className = '' }) => {
  let IsUrgentHandling = URGENT_OPTIONS.find(
    (urgent) => urgent?.key === reflect?.isUrgentHandling
  );
  if (!IsUrgentHandling) {
    IsUrgentHandling = URGENT_OPTIONS?.[1];
  }

  let reflectImage = '/assets/reflect/test-thumb.png';
  // if (reflect?.reflectionFilesViewDtos?.filter(item => isImage(item?.fileName))?.[0]?.filePath) {
  //   reflectImage = BASE_IMAGE + reflect?.reflectionFilesViewDtos?.filter(item => isImage(item?.fileName))?.[0]?.filePath;
  // }
  if (reflect?.image) {
    reflectImage = BASE_IMAGE + reflect?.image;
  }

  return (
    <Link
      to={`/phan-anh-hien-truong/phan-anh/${reflect?.id}`}
      className={`reflect-card reflect-card__horizontal ${className}`}
    >
      <LazyLoadImage src={reflectImage} alt={reflect?.title}></LazyLoadImage>
      <div className='card-content'>
        <div
          className={`card-content__type ${
            reflect?.isUrgentHandling
              ? 'card-content--red'
              : 'card-content--blue'
          }`}
        >
          {IsUrgentHandling?.label}
        </div>
        <div className='card-content__placement'>
          <PlaceOutlined />
          {reflect?.detectionPosition}
        </div>
        <div className='card-content__title'>{reflect?.title}</div>
        <div
          className='card-content__description'
          dangerouslySetInnerHTML={{ __html: reflect?.content }}
        />
        <div className='card-content__time'>
          <QueryBuilderOutlined />
          {dateVNFormat(reflect?.createdDate, 'DD/MM/YYYY hh:mm')}&nbsp;
        </div>
      </div>
    </Link>
  );
};
