import React, { useEffect, useState } from "react";
import "./look-up-planning.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SearchByDistrict } from "./child-modules/planning-district/search-district.view";
import PlanningByDistrictView from "./child-modules/planning-district/planning-district.view";
import UrlCollect from "../../common/url-collect";
import * as statementActions from "../../redux/store/statement/statement.store";
import history from "../../common/history";
import * as appActions from "../../core/app.store";

function LookUpPlanningView(props) {

  const { showLoading} = props;
  const [planningType, setPlanningType] = useState([]);
  const [dataSelectBoxSearch, setDataSelectBoxSearch] = useState([]);
  const [selected, setSelected] = useState();
  const [show, setShow] = useState(true);
  const [listDistrict, setListDistrict] = useState([]);
  const [districtSelect, setDistrictSelect] = useState(new URLSearchParams(props.location.search).get("district") ?
    new URLSearchParams(props.location.search).get("district").split(',') : []);
  const [planningUnitSelect, setPlanningUnitSelect] = useState(new URLSearchParams(props.location.search).get("planningUnit") ?
    new URLSearchParams(props.location.search).get("planningUnit") : '');
  const [approvalAgencySelect, setApprovalAgencySelect] = useState(new URLSearchParams(props.location.search).get("approvalAgency") ?
    new URLSearchParams(props.location.search).get("approvalAgency") : '');
  const [name, setName] = useState(new URLSearchParams(props.location.search).get("name") ?
    new URLSearchParams(props.location.search).get("name") : '');
  const [planningTypeId, setPlanningTypeId] = useState(new URLSearchParams(props.location.search).get("key") ?
    new URLSearchParams(props.location.search).get("key") : '');

  const [yearSearch, setYearSearch] = useState(new URLSearchParams(props.location.search).get("yearSearch") ?
  new URLSearchParams(props.location.search).get("yearSearch") : '');

  const [numberOfDecisions, setNumberOfDecisions] = useState(new URLSearchParams(props.location.search).get("numberOfDecisions") ?
    new URLSearchParams(props.location.search).get("numberOfDecisions") : '');
  const [constructionGroup, setConstructionGroup] = useState(new URLSearchParams(props.location.search).get("constructionGroup") ?
    new URLSearchParams(props.location.search).get("constructionGroup") : '');

  useEffect(() => {
    getAllPlanningType();
  }, []);

  useEffect(() => {
    showLoading(true);

    setDistrictSelect(new URLSearchParams(window.location.search).get("district") ?
      new URLSearchParams(window.location.search).get("district").split(',') : '');

    setPlanningUnitSelect(new URLSearchParams(window.location.search).get("planningUnit") ?
      new URLSearchParams(window.location.search).get("planningUnit").split(',') : '');

    setApprovalAgencySelect(new URLSearchParams(window.location.search).get("approvalAgency") ?
      new URLSearchParams(window.location.search).get("approvalAgency").split(',') : '');
      
    setName(new URLSearchParams(window.location.search).get("name") || '');
    setYearSearch(new URLSearchParams(window.location.search).get("yearSearch") || '');
    setNumberOfDecisions(new URLSearchParams(window.location.search).get("numberOfDecisions") || '');
    setConstructionGroup(new URLSearchParams(window.location.search).get("constructionGroup") || '');
    setPlanningTypeId(new URLSearchParams(window.location.search).get("key") || '');
    console.log(new URLSearchParams(window.location.search).get("district"));
    showLoading(false);
    
  }, [  
      new URLSearchParams(window.location.search).get("key"),
      new URLSearchParams(window.location.search).get("district"),
      new URLSearchParams(window.location.search).get("planningUnit"),
      new URLSearchParams(window.location.search).get("name"),
      new URLSearchParams(window.location.search).get("yearSearch"),
      new URLSearchParams(window.location.search).get("numberOfDecisions"),
      new URLSearchParams(window.location.search).get("constructionGroup"),
    ])

  const getAllPlanningType = () => {
    statementActions
      .PlanningType()
      .then((res) => {
        if (res && res.content) {
          setPlanningType(res.content);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  let PATHNAME =
    "?key=" + new URLSearchParams(props.location.search).get("key");

  function createClassListLeftSelectMenu(pathName) {
    return PATHNAME === pathName ? "p-2 active-custom" : "p-2";
  }

  const onSubmit = () => {
    history.push(`${UrlCollect.PlanningMap}?key=${planningTypeId}&name=${name}&district=${districtSelect ? districtSelect.join(',') : ''}&planningUnit=${planningUnitSelect}&approvalAgency=${approvalAgencySelect}&yearSearch=${yearSearch}&numberOfDecisions=${numberOfDecisions}&constructionGroup=${constructionGroup}`)
  };



  return (
    <div className="container look-up-planning-container">
      <div className="row">
        <div className="col-12 col-xl-3 left-menu">
          <SearchByDistrict
            params={props}
            onSubmit={onSubmit}
            name={name}
            setName={(data) => setName(data)}
            districtSelect={districtSelect}
            setDistrictSelect={setDistrictSelect}
            planningUnitSelect={planningUnitSelect}
            setPlanningUnitSelect={setPlanningUnitSelect}
            approvalAgencySelect={approvalAgencySelect}
            setApprovalAgencySelect={setApprovalAgencySelect}
            numberOfDecisions={numberOfDecisions}
            setNumberOfDecisions={setNumberOfDecisions}
            constructionGroup={constructionGroup}
            setConstructionGroup={setConstructionGroup}
            yearSearch={yearSearch}
            setYearSearch={setYearSearch}
            isLookup={true}
          />
          
          <div className="list-select-side-menu">
            <Link
              key={"SyntheticPlanning"}
              to={UrlCollect.SyntheticPlanning}
              className={createClassListLeftSelectMenu(
                UrlCollect.SyntheticPlanning
              )}
            >
              Tổng hợp quy hoạch
            </Link>
            {planningType &&
              planningType.length > 0 &&
              planningType.map((item, index) => (
                <Link
                  key={index}
                  to={
                    UrlCollect.PlanningMap 
                    + "?key=" 
                    + item.id 
                    + '&name=' 
                    + name 
                    + '&district=' 
                    + districtSelect 
                    + '&planningUnit=' 
                    + planningUnitSelect
                    + '&approvalAgency=' 
                    + approvalAgencySelect
                    + '&yearSearch=' 
                    + yearSearch
                    + '&numberOfDecisions=' 
                    + numberOfDecisions 
                    + '&constructionGroup=' 
                    + constructionGroup 
                  }
                  className={createClassListLeftSelectMenu("?key=" + item.id)}
                >
                  {item.name}
                </Link>
              ))}
          </div>
        </div>
        <div className="col-md-9 col-12">
          <PlanningByDistrictView
            params={props}
            setDataSelectBoxSearch={setDataSelectBoxSearch}
            selected={selected}
            planningTypeId={planningTypeId}
            name={name}
            districtSelect={districtSelect}
            planningUnitSelect={planningUnitSelect}
            approvalAgencySelect={approvalAgencySelect}
            yearSearch={yearSearch}
            numberOfDecisions={numberOfDecisions}
            constructionGroup={constructionGroup}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  listPlanningCategoriesAll: state.mapData.listPlanningCategoriesAll,
  openLayerController: state.openlayer.openLayerController,
  planningType: state.statement.planningType,
  listPolygon: state.mapData.listPolygon,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(LookUpPlanningView)
