import React from 'react';
import { Pagination } from '@material-ui/lab';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './pagination.scss';

const ReflectPagination = ({ className = '', ...props }) => {

  return (
    <Pagination
      className={`reflect-pagination ${className}`}
      variant='outlined'
      shape='rounded'
      {...props}
    />
  )
};

export default ReflectPagination;
