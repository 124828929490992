import React, { useState, useEffect, Component } from "react";
import ol from "ol";
import OlMap from "ol/Map";
import OlView from "ol/View";
import OlLayerTile from "ol/layer/Tile";
import OlSourceOSM from "ol/source/OSM";
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature'
import { fromLonLat, transform, toLonLat } from "ol/proj";
import {Icon, Style} from 'ol/style';
import VectorSource from 'ol/source/Vector';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';

const iconStyle = new Style({
  image: new Icon({
    anchor: [0.5, 1],
    src: '/assets/reflect/location.png',
    scale: 0.03,
  }),
});

const point = new Point([0, 0])

const iconFeature = new Feature(point);
iconFeature.set('style', iconStyle);

const markerLayer = new VectorLayer({
  className: 'marker',
  style: function (feature) {
    return feature.get('style');
  },
  source: new VectorSource({features: [iconFeature]}),
  visible: false
})

const OpenlayersMapView = ({ lng, lat, onSelect }) => {
  const [mapState, setMapState] = useState({ center: fromLonLat([104, 21]), zoom: 10 })
  const [olMap, setOlMap] = useState(new OlMap({
    target: null,
    layers: [
      new OlLayerTile({
        source: new OlSourceOSM()
      }),
      markerLayer,
    ],
    view: new OlView({
      center: mapState.center,
      zoom: mapState.zoom
    })
  }))

  const handleMapClick = (event) => {
    const clickedCoord = olMap.getCoordinateFromPixel(event.pixel);
    const transormedCoord = toLonLat(clickedCoord)
    markerLayer.setVisible(true)
    // point.setCoordinates(clickedCoord)
    onSelect(transormedCoord)
  }

  const updateMap = () => {
    olMap.getView().setCenter(mapState?.center);
    olMap.getView().setZoom(olMap.getView().getZoom());
  }

  useEffect(() => {
    updateMap()
  }, [mapState])
  
  useEffect(() => {
    setMapState({ center: fromLonLat([lng || 104, lat || 21]), zoom: 10 });
    if (lng && lat) {
      point.setCoordinates(fromLonLat([lng, lat]))
      markerLayer.setVisible(true)
    } else {
      markerLayer.setVisible(false)
    }
  }, [lng, lat])

  useEffect(() => {
    olMap.setTarget("map");
    olMap.on("moveend", () => {
      let center = olMap.getView().getCenter();
      let zoom = olMap.getView().getZoom();
      setMapState({ center, zoom });
    });
    olMap.on('click', handleMapClick)
  }, [])

  return (
    <div id="map" className="mt-4" style={{ width: "100%", height: "360px" }}>
    </div>
  );
}

export default OpenlayersMapView;
