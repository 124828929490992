import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { CreateComment, UpdateComment } from '../../../../redux/store/reflect/reflect-comments.store'
import ShowNotification from '../../../../components/react-notifications/react-notifications';
import { NotificationMessageType } from '../../../../utils/configuration';
import { isImage, isVideo, isDocument } from 'utils/file';
import PreviewMedia from '../media/preview-media.view'
import './comments.scss'

const ReflectCommentForm = ({ className = "", reflectId, parentId = null, onSuccess, onClose, comment }) => {
  const [isLoading, setLoading] = useState()

  const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm({
    defaultValues: {
      Content: comment?.content || '',
      files: comment?.commentFilesViewDtos || [],
      deletedFiles: [],
    }
  });

  const watchFields = watch([
    'files',
    'deletedFiles',
  ]);

  const onSubmit = async (values) => {
    if (!values.Content) return
    setLoading(true)
    if (comment) {
      const newComment = {
        Id: comment?.id,
        ParentId: comment?.parentId || '',
        Content: values.Content,
        ReflectionId: reflectId,
        IsActive: true,
        IsDelete: false,
        files: values?.files,
        deletedFiles: values?.deletedFiles,
      }
      await UpdateComment(newComment)
    } else {
      const newComment = {
        Content: values.Content,
        ParentId: parentId || '',
        ReflectionId: reflectId,
        IsActive: true,
        IsDelete: false,
        files: values?.files,
        deletedFiles: values?.deletedFiles,
      }
      await CreateComment(newComment)
      setValue('Content', '')
      setValue('files', [])
    }
    setLoading(false)
    onSuccess && onSuccess()
  }

  const handleChangeFile = (files) => {
    console.log(files)
    const fileTypeExist = {
      image: 0,
      video: 0,
      document: 0,
    }
    const currentFiles = watchFields.files || []
    // for (let file of currentFiles) {
    //   if (file instanceof File && file?.type?.includes("image/")) {
    //     fileTypeExist.image++
    //   }
    //   if (typeof file?.filePath === "string" && isImage(file?.filePath)) {
    //     fileTypeExist.image++
    //   }
    //   if (file instanceof File && file?.type?.includes("video/")) {
    //     fileTypeExist.video++
    //   }
    //   if (typeof file?.filePath === "string" && isVideo(file?.filePath)) {
    //     fileTypeExist.video++
    //   }
    //   if (file instanceof File && isDocument(file?.name)) {
    //     fileTypeExist.document++
    //   }
    //   if (typeof file?.filePath === "string" && isDocument(file?.filePath)) {
    //     fileTypeExist.document++
    //   }
    // }
    // for (let file of files) {
    //   if (file?.type?.includes("image/") && fileTypeExist?.image >= 3) {
    //     ShowNotification(
    //       `Chỉ được đính kèm tối đa 3 ảnh, 1 video và 1 tài liệu`,
    //       NotificationMessageType.Error
    //     );
    //     return
    //   }
    //   if (file?.type?.includes("video/") && fileTypeExist?.video >= 1) {
    //     ShowNotification(
    //       `Chỉ được đính kèm tối đa 3 ảnh, 1 video và 1 tài liệu`,
    //       NotificationMessageType.Error
    //     );
    //     return
    //   }
    //   if (isDocument(file?.name) && fileTypeExist?.document >= 1) {
    //     ShowNotification(
    //       `Chỉ được đính kèm tối đa 3 ảnh, 1 video và 1 tài liệu`,
    //       NotificationMessageType.Error
    //     );
    //     return
    //   }
    // }
    currentFiles.push(...files)
    setValue(`files`, currentFiles)
  }
  
  useEffect(() => {
    register('files');
    register('deletedFiles');
  }, []);

  const fileToRender = watchFields?.files?.map((file, id) => ({ id, file }));

  return <div className={"comment-item comment-form mt-4 " + `${className}`}>
    <div className="comment-item__avatar">
      <img src="/assets/reflect/default-avatar.png" alt="" />
    </div>
    <form className="comment-item__body" onSubmit={handleSubmit(onSubmit)}>
      <textarea
        className="form-control"
        rows="4"
        ref={register}
        name="Content"
      ></textarea>
      <div className='preview-list'>
        {fileToRender
          ?.filter(
            (item) => !isDocument(item?.file?.name || item?.file?.fileName)
          )
          ?.map((item, i) => (
            <div className='preview-item' key={i}>
              <PreviewMedia file={item?.file} key={i} />
              <div
                className='close-btn'
                onClick={() => {
                  if (item?.file?.fileId) {
                    setValue(`deletedFiles`, [
                      ...(watchFields?.deletedFiles || []),
                      item?.file,
                    ]);
                  }
                  const filesClone = [...watchFields?.files];
                  filesClone.splice(i, 1);
                  setValue(`files`, filesClone);
                }}
              >
                X
              </div>
            </div>
          ))}
      </div>
      <div className='comment-form__action'>
        <label htmlFor='comment-add'>
          Thêm ảnh/video
        </label>
        <input
          id="comment-add"
          hidden
          type="file"
          onChange={(e) => handleChangeFile(e?.target?.files)}
          accept="image/*,video/*"
          multiple
        />
        {onClose && <button type="button" onClick={onClose}>Đóng</button>}
        <button disabled={isLoading}>{comment ? 'Sửa' : 'Gửi'} bình luận</button>
      </div>
    </form>
  </div>
}

export default ReflectCommentForm
