import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as homePageAction from "../../../redux/store/home-page/home-page.store";
import './lookupPlanning.scss'
import { APIUrlDefault } from "../../../utils/configuration";
import dateformat from "dateformat";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
export const LookupPlanningType = (props) => {
    const {
        showLoading,
        planningTypeId,
        planningName,
    } = props;

    const [listResult, setListResult] = useState([]);
    const [paging, setPaging] = useState();
    const [currentPage, setCurrentPage] = useState();
    const [firstPlanning, setFirstPlanning] = useState({});
    const pageSize = 4;
    useEffect(() => {
        onGetData();
    }, []);

    const onGetData = () => {
        showLoading(true);
        Promise.all([
            onGetPlanningSearchByIdName({
                planningTypeId,
                pageIndex: 1,
                pageSize: pageSize,
            }),
        ])
            .then((res) => {
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };


    const onGetPlanningSearchByIdName = (data) => {
        return new Promise((resolve, reject) => {
            homePageAction.GetPlanningSearchByIdNameHome(data.planningTypeId, data.pageIndex, data.pageSize)
                .then((res) => {
                    setListResult(
                        res &&
                            res.content &&
                            res.content.items
                            && res.content.items.length > 0 ? res.content.items : []
                    );
                    setPaging(res.content);
                    setCurrentPage(res.content.pageIndex);
                    setFirstPlanning(res.content.items[0]);

                    resolve(res);
                },
                    (err) => {
                        reject(err);
                    })

        })
    }

    const getNextPage = () => {
        let nextPage = 1;
        if (currentPage > 0) {
            nextPage = currentPage + 1;
        }
        onGetPlanningSearchByIdName({
            pageIndex: nextPage,
            planningTypeId,
            pageSize: pageSize
        })
    }

    const getPreviousPage = () => {
        let prePage = 1;
        if (currentPage > 1) {
            prePage = currentPage - 1;
        }
        onGetPlanningSearchByIdName({
            pageIndex: prePage,
            planningTypeId,
            pageSize: pageSize
        })
    }

    return (
        <div>
            <div className="container">
                <div className="title__planning-type">
                    <div className="row">
                        <div className="col">
                            <Link to={'ban-do-quy-hoach?key=' + planningTypeId}><h3>{planningName}</h3></Link>
                        </div>
                        <div className="col">
                            <div className="pagination-nav">
                                {
                                    paging && paging.hasPreviousPage && (
                                        <a className="mr-3" onClick={getPreviousPage}>
                                            <FontAwesomeIcon icon={faArrowLeft} className="icon-link" />
                                        </a>
                                    )
                                }

                                <b>{paging && paging.pageIndex} / {paging && paging.pageCount}</b>

                                {paging && paging.hasNextPage && (
                                    <a className="ml-3" onClick={getNextPage}>
                                        <FontAwesomeIcon icon={faArrowRight} className="icon-link" />
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 mb-4">
                        <Link to={"/ban-do-quy-hoach/" + firstPlanning.id}>
                            <LazyLoadImage
                                className="first-img"
                                src={APIUrlDefault + firstPlanning.photo}
                                onError={(e) =>
                                    (e.target.src = require("../../../assets/image/noImage.png"))
                                }
                                alt={firstPlanning.photo}>
                            </LazyLoadImage>
                        </Link>
                        <div className="planning__date">{dateformat(firstPlanning.publishDate, "dd/mm/yyyy")}</div>
                        <Link to={"/ban-do-quy-hoach/" + firstPlanning.id} className="planning__title">
                            <p>{firstPlanning.name}</p>
                        </Link>
                    </div>
                    <div className="col-lg-6">
                        <div className="">
                            {listResult.slice(1).map((item, index) => (
                                <div key={index} className='row planning__item'>
                                    <div className="col-5">
                                        <Link to={"/ban-do-quy-hoach/" + item.id}>
                                            <LazyLoadImage
                                                className="planning-img"
                                                src={APIUrlDefault + item.photo}
                                                onError={(e) =>
                                                    (e.target.src = require("../../../assets/image/noImage.png"))
                                                }
                                                alt={item.photo}>
                                            </LazyLoadImage>
                                        </Link>
                                    </div>
                                    <div className="col-7">
                                        <Link to={"/ban-do-quy-hoach/" + item.id} className="planning__title">
                                            <p>{item.name}</p>
                                        </Link>
                                        <div className="planning__date">{dateformat(item.publishDate, "dd/mm/yyyy")}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

