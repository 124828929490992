import { Map } from "immutable";

const APP_LOADING = "APP_LOADING";

export const onLoading = (isLoading) => ({ type: APP_LOADING, isLoading });

export const ShowLoading = (isLoading) => {
    return (dispatch) => {
        dispatch(onLoading(isLoading));
    };
};

export const initialState = Map({
    loading: false,
}).toJS();

export default function AppReducer(state = initialState, action) {
    switch (action.type) {
        case APP_LOADING:
            return {
                ...state,
                loading: action.isLoading,
            };
        default:
            return { ...state };
    }
}
