import Service from '../../../api/api-service-custom';
import { ApiUrl } from '../../../api/api-url';

const service = new Service();

const defaultKey = "29350440eb729a1980a474cb3953ceea"

export const autoSuggest = (text) => {
  return service
    .get(`https://api.map4d.vn/sdk/autosuggest?text=${text}&Key=${defaultKey}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const geoCode = (paramObj) => {
  const params = new URLSearchParams();
  if (typeof paramObj === 'object') {
    for (let key in paramObj) {
      params.append(key, paramObj[key])
    }
  }
  return service
    .get(`https://api.map4d.vn/sdk/v2/geocode?key=${defaultKey}&${params}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
