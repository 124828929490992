import { Circle, Fill, Stroke, Style, Icon } from "ol/style";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
export const VectorImageLayerClassName = (layerId: number) =>
  `vector-image-layer-${layerId}`;
export const TileLayerClassName = (layerId: number) => `title-layer-${layerId}`;
export const Default_Feature_Style: any = (_feature: Feature) => {
  const _defaultWidth = 1;
  const _PolygonStyle = new Style({
    stroke: new Stroke({
      color: "rgba(245, 0, 0, 0.01)",
      width: _defaultWidth,
    }),
    fill: new Fill({
      color: "rgba(0, 0, 0, 0)",
    }),
  });
  const _PointStyle = new Style({
    image: new Circle({
      radius: _defaultWidth * 4,
      fill: new Fill({
        color: "rgba(0, 0, 0, 0)",
      }),
      stroke: new Stroke({
        color: "rgba(0, 0, 0, 0)",
        width: _defaultWidth / 4,
      }),
    }),
    zIndex: Infinity,
  });
  switch (true) {
    case _feature.getGeometry() instanceof Point:
      return _PointStyle;
    default:
      return _PolygonStyle;
  }
};

export const Highlight_Feature_Style: any = (_feature: Feature) => {
  const _defaultWidth = 3;
  const _PolygonStyle = new Style({
    stroke: new Stroke({
      color: "rgba(0,230,241,1)",
      width: _defaultWidth,
    }),
    fill: new Fill({
      color: "rgba(130,230, 80,0.5)",
    }),
  });
  const _PointStyle = new Style({
    image: new Circle({
      radius: _defaultWidth * 2,
      fill: new Fill({
        color: "blue",
      }),
      stroke: new Stroke({
        color: "white",
        width: _defaultWidth / 2,
      }),
    }),
    zIndex: Infinity,
  });
  switch (true) {
    case _feature.getGeometry() instanceof Point:
      return _PointStyle;
    default:
      return _PolygonStyle;
  }
};

export const Draw_Filter_Feature_Style: any = (_feature: Feature) => {
  const _defaultWidth = 1;
  const _anchorXUnits: any = "fraction";
  const _anchorYUnits: any = "pixels";
  const _PolygonStyle = new Style({
    stroke: new Stroke({
      color: "red",
      width: _defaultWidth,
    }),
    fill: new Fill({
      color: "rgba(231, 95, 95, 0.51)",
    }),
  });
  const _PointStyle = new Style({
    image: new Icon({
      anchor: [0.5, 22],
      anchorXUnits: _anchorXUnits,
      anchorYUnits: _anchorYUnits,
      src: require("../../../assets/icon/red-marker.png"),
    }),
  });
  switch (true) {
    case _feature.getGeometry() instanceof Point:
      return _PointStyle;
    default:
      return _PolygonStyle;
  }
};
