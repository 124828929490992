import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import CreateOrUpdateReflectView from '../create-reflect-page/create-or-update-reflect.view'

const EditModal = ({ show, onHide, reflect, onSuccess }) => {
  return (
    <Modal show={show} onHide={onHide} size="xl">
      <div className='p-4'>
        <CreateOrUpdateReflectView reflect={reflect} onSuccess={onSuccess} />
      </div>
    </Modal>
  );
};

export default EditModal;
