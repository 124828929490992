import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UrlCollect from "../../../common/url-collect";
import * as statementAction from "../../../redux/store/statement/statement.store";
import * as configuration from "../../../utils/configuration";

import MapLink from "./map-link.view";
import ServiceLink from "./service-link.view";

export default function MenuRightView(props) {
  const [serviceModels, setServiceModels] = useState(null);

  useEffect(() => {
    statementAction
        .GetSerivceLink()
        .then(
            (res) =>
                res &&
                res.content &&
                res.content.linkGroup &&
                setServiceModels(res.content.linkGroup)
        )
        .catch((err) => {});
  }, []);

  return (
      <div className="col-12 col-md-12 col-lg-4 pl-2 pr-2">
        <MapLink />
        <div className="content-result">
          <ServiceLink />
        </div>
      </div>
  );
}
