import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarAlt,
    faVideo,
    faFilm,
    faEye,
    faCamera,
    faQuestionCircle,
    faBook, faLink, faAngleDoubleDown,
} from "@fortawesome/free-solid-svg-icons";
import { faNewspaper, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import * as statementAction from "../../../redux/store/statement/statement.store";
import * as configuration from "../../../utils/configuration";

export default function ServiceLink() {
    const [linkGroup, setLinkGroup] = useState(null);

    useEffect(() => {
        statementAction
            .GetSerivceLink()
            .then(
                (res) =>
                    res &&
                    res.content &&
                    res.content.linkGroup &&
                    setLinkGroup(res.content.linkGroup)
            )
            .catch((err) => {});
    }, []);

    function getOtherLinkIcon(iconName){
        var icon = <FontAwesomeIcon icon={faLink} />
        switch(iconName){
            case 'faCalendarAlt':
            case 'fa-calendar-alt':
                icon = <FontAwesomeIcon icon={faCalendarAlt} />
                break;
            case 'faVideo':
            case 'fa-video':
                icon = <FontAwesomeIcon icon={faVideo} />
                break;
            case 'faFilm':
            case 'fa-film':
                icon = <FontAwesomeIcon icon={faFilm} />
                break;
            case 'faCamera':
            case 'fa-camera':
                icon = <FontAwesomeIcon icon={faCamera} />
                break;
            case 'faBook':
            case 'fa-book':
                icon = <FontAwesomeIcon icon={faBook} />
                break;
            case 'faEye':
            case 'fa-eye':
                icon = <FontAwesomeIcon icon={faEye} />
                break;
            case 'faQuestionCircle':
            case 'fa-question-circle':
                icon = <FontAwesomeIcon icon={faQuestionCircle} />
                break;
        }
        return icon;
    }
  return (
      <div>
          {linkGroup &&
          linkGroup.map((items, index) =>
              items.displayType === "IMAGE" ? (
                  <div className="item mb-1">
                      {items.linkService &&
                      items.linkService.length > 0 &&
                      items.linkService.map((linkService, index1) => (
                          <div className="row" key={index1}>
                              <div className="col-12">
                                  <a
                                      href={linkService.link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                  >
                                      <img
                                          src={
                                              configuration.APIUrlDefault + linkService.image
                                          }
                                          alt="Map"
                                          className="img-fluid w-100 mb-2 mb-md-0"
                                      />
                                  </a>
                              </div>
                          </div>
                      ))}
                  </div>
              ) : items.displayType === "OTHER" ? (
                  <div className="menu-item-link">
                      <ul className="list-unstyled">
                          {items.linkService &&
                          items.linkService.length > 0 &&
                          items.linkService.map((itemOther,index2) => (
                              <li key={index2}>
                                  <a href={itemOther.link} target="_blank">
                                      <span>{getOtherLinkIcon(itemOther.icon)}</span>
                                      {itemOther.name}
                                  </a>
                              </li>
                          ))}
                      </ul>
                  </div>
              ) : items.displayType === "MAP" ? (
                  <span></span>
              ) :(
                  <div>
                      <div className="header-result">
                          <div className="title">
                              <a
                                  className="text-uppercase"
                                  data-toggle="collapse"
                                  href={"#Parent" + index}
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="department"
                              >
                                  <FontAwesomeIcon
                                      icon={faAngleDoubleDown}
                                      className="mr-2"
                                  />
                                  {items.name}
                              </a>
                          </div>
                      </div>
                      {items.linkService &&
                      items.linkService.length > 0 &&
                      items.linkService.map((itemsChild) => (
                          <div className="collapse" id={"Parent" + index}>
                              <div className="content-result pt-2 pb-2 mt-0">
                                  <ul className="m-0">
                                      <li>
                                          <a
                                              href={itemsChild.link}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                          >
                                              {itemsChild.name}
                                          </a>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      ))}
                  </div>
              )
          )}
      </div>
  );
}
