import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../core/app.store";
import Pagination from "../../components/pagination/pagination.view";
import UrlCollect from "../../common/url-collect";
import ServiceLink from "../planning-announcement/menu-right/service-link.view";
import {
  APIUrlDefault, DomainUserSide,
  NotificationMessageType,
  NotificationPosition,
} from "../../utils/configuration";
import MenuRightView from "./menu-right/menu-right.view";
import "@geedmo/yamm/src/yamm.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./list.scss";
import * as newsActions from "../../redux/store/news/news.store";
import dateformat from "dateformat";

const firstLoad = true;

function ListNewsView(props) {
  const { showLoading, isQHT, isQHCC, isQHHTKT} = props;
  const [keyword, setKeyword] = useState([]);

  const [currentPageIndexDoing, setCurrentPageIndexDoing] = useState(1);
  const currentPageSizeDoing = 4;
  const [totalItemCountDoing, setTotalItemCountDoing] = useState(0);

  const [pageCountDoing, setPageCountDoing] = useState();
  const [listHotNews, setHotNewsModel] = useState([]);
  const [listNews, setListNews] = useState([]);
  const [hotNews, setHotNews] = useState({});

  useEffect(() => {
    onGetData();
  }, [firstLoad]);

  const onGetData = () => {
    showLoading(true);
    console.log(isQHHTKT)
    console.log(isQHT)
    console.log(isQHCC)
    Promise.all([
      onGetListHotNews({
          pageIndex: 1,
          pageSize: 5,
          // isQHT: isQHT, 
          // isQHCC: isQHCC, 
          // isQHHTKT: isQHHTKT,
      }),
      onGetListNews({
          pageIndex: currentPageIndexDoing,
          pageSize: currentPageSizeDoing,
          isQHT: isQHT, 
          isQHCC: isQHCC, 
          isQHHTKT: isQHHTKT,
      }),
    ])
        .then((res) => {
          showLoading(false);
        })
        .catch((err) => {
          showLoading(false);
        });
  };

    const setTextSearch = (event) => {
      setKeyword(event.target.value);
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            onGetListNews({
                    keyword:keyword,
                    pageIndex: 1,
                    pageSize: currentPageSizeDoing,
                    isQHT: isQHT, 
                    isQHCC: isQHCC, 
                    isQHHTKT: isQHHTKT,
                }
            );
        }
    };

    const handleClickSearchIcon = () => {
        onGetListNews({
                keyword:keyword,
                pageIndex: 1,
                pageSize: currentPageSizeDoing,
                isQHT: isQHT, 
                isQHCC: isQHCC, 
                isQHHTKT: isQHHTKT,
            }
        );
    };

    const onGetListNews = (data) => {
        return new Promise((resolve, reject) => {
            newsActions.GetListNewsByCategory(data).then(
                (res) => {
                    setListNews(
                        res &&
                        res.content &&
                        res.content.items
                        && res.content.items.length > 0 ? res.content.items: []
                    );
                    setTotalItemCountDoing(
                        res && res.content && res.content.totalItemCount
                            ? res.content.totalItemCount
                            : 0
                    );
                    setPageCountDoing(
                        res && res.content && res.content.pageCount
                            ? res.content.pageCount
                            : 0
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

  const onGetListHotNews = (data) => {
    return new Promise((resolve, reject) => {
      newsActions.GetListHotNews(data).then(
          (res) => {
            setHotNewsModel(
                res &&
                res.content &&
                res.content.items
                && res.content.items.length > 0 ? res.content.items: []
            );
            resolve(res);
          },
          (err) => {
            reject(err);
          }
      );
    });
  }

  const onPagingClick = (data, isClearSearch = false) => {
        onGetListNews({
                keyword:keyword,
                pageIndex: data.pageIndex,
                pageSize: currentPageSizeDoing,
                isQHT: isQHT, 
                isQHCC: isQHCC, 
                isQHHTKT: isQHHTKT,
            }
        );
  };

  return (
      <div className="container news reset-news-bg">
        <div className="community-opinion mb-4">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
              <nav className="yamm navbar navbar-expand header-doing">
                <h6 className="navbar-brand title mb-0">
                  {isQHT? 'TIN TỨC QUY HOẠCH TỈNH': isQHCC? 'TIN TỨC QUY HOẠCH CÁC CẤP': 'TIN TỨC NỘI DUNG HẠ TẦNG KĨ THUẬT'}
                </h6>

                <div className="navbar-collapse"></div>

                <div className="dropdown yamm-fw">
                    <input
                        type="text"
                        name="search"
                        id="search"
                        className="input-search float-right"
                        onChange={setTextSearch}
                        onKeyPress={handleKeyPress}
                    />

                  <FontAwesomeIcon
                      icon={faSearch}
                      className="icon-search dropdown-toggle"
                      onClick={handleClickSearchIcon}
                  />
                </div>
              </nav>
                <div className="other-news">
                    {Array.isArray(listNews) && listNews.length >0 ? (
                        listNews.map((item,index) => (
                            index === 0 ? (
                                <div className="hot-new">
                                    <div className="new-image">
                                    <Link to={"/tin-tuc-chi-tiet/" + item.id}>
                                        <img
                                            src={APIUrlDefault + item.image_Url}
                                            onError={(e) =>
                                                (e.target.src = require("../../assets/image/noImage.png"))
                                            }
                                            alt={item.title}
                                        />
                                      </Link>
                                    </div>
                                    <Link to={"/tin-tuc-chi-tiet/" + item.id} className="new-title">{item.title}</Link>
                                    <div className="new-date">{dateformat(item.created_date, "dd/mm/yyyy")}</div>
                                    <p className="new-desc" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                </div>
                        ):(
                            <div key={index} className="new-item">
                                <div className="new-image">
                                    <a href={DomainUserSide + "/tin-tuc-chi-tiet/" + item.id} >
                                        <img
                                            src={APIUrlDefault + item.image_Url}
                                            onError={(e) =>
                                                (e.target.src = require("../../assets/image/noImage.png"))
                                            }
                                            alt={item.title}
                                        />
                                    </a>
                                </div>
                                <div className="new-info">
                                    <a href={"/tin-tuc-chi-tiet/" + item.id}  className="new-title">{item.title}</a>
                                    <div className="news-create-date">{dateformat(item.created_date, "dd/mm/yyyy")}</div>
                                    <div className="news-description">{item.description}</div>
                                </div>
                            </div>
                        )))
                    ):(
                        <div className="no-data-for-display">Chưa có dữ liệu để hiển thị!</div>
                    )}
                </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
              <div className="header-result">
                <div className="title">
                  <Link to="#" className="text-uppercase">
                    TIN MỚI NHẤT
                  </Link>
                </div>
              </div>
              <div className="list-hot-news">
                {listHotNews && listHotNews.length >0 ? (
                    listHotNews.map((item,index) => (
                        <div key={index} className="new-item">
                          <div className="new-image">
                            <a href={DomainUserSide + "/tin-tuc-chi-tiet/" + item.id} >
                              <img
                                  src={APIUrlDefault + item.image_Url}
                                  onError={(e) =>
                                      (e.target.src = require("../../assets/image/noImage.png"))
                                  }
                                  alt={item.title}
                              />
                            </a>
                          </div>
                          <div className="new-info">
                            <a href={"/tin-tuc-chi-tiet/" + item.id}  className="new-title">{item.title}</a>
                            <div className="new-date">{item.createDate}</div>
                          </div>
                        </div>
                    ))
                ):(
                    <div className="new-item">Chưa có dữ liệu để hiển thị!</div>
                )}
                  <ServiceLink />
              </div>
            </div>

          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8 d-none d-lg-block">
              {listNews && listNews.length > 0 && (
                  <div className="list-footer">
                    <div
                        className={`text-center text-result ${
                            pageCountDoing && pageCountDoing > 1 ? "float-left" : ""
                        }`}
                    >
                      <span>Có {totalItemCountDoing} kết quả</span>
                    </div>
                    {pageCountDoing && pageCountDoing > 1 && (
                        <div className="float-right">
                          <Pagination
                              totalItems={totalItemCountDoing}
                              currentIndex={currentPageIndexDoing}
                              pageSize={currentPageSizeDoing}
                              onClick={(pageIndex) => {
                                onPagingClick({
                                  pageIndex: pageIndex
                                });
                              }}
                          />
                        </div>
                    )}
                  </div>
              )}
            </div>
          </div>
        </div>
      </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
          showLoading: appActions.ShowLoading,
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListNewsView);
