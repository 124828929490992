import { Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useForm } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
// import OpenlayersMapView from "../../openlayers-map/openlayers-map.view";
import OpenlayersMapView from '../components/openlayers-map/openlayers-map.view';

import './create-reflect.scss';
import {
  CreateReflect,
  UpdateReflect,
} from '../../../redux/store/reflect/reflect.store';
import { useAutoSuggestAddress } from '../hooks/map4d';
import { geoCode } from '../../../redux/store/reflect/map4d';
// import { BASE_IMAGE } from '../../../common/constants';
import ShowNotification from '../../../components/react-notifications/react-notifications';
import { NotificationMessageType } from '../../../utils/configuration';
import { isImage, isVideo, isDocument } from 'utils/file';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { apiCustom } from 'api/api-config'
const BASE_IMAGE = apiCustom + "/";
function CreateReflectPage({ reflect, onSuccess }) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      Id: reflect?.id || undefined,
      Title: reflect?.title || '',
      Content: reflect?.content || '',
      DetectionPosition: reflect?.detectionPosition || '',
      Longitude: reflect?.longitude || '',
      Latitude: reflect?.latitude || '',
      IsUrgentHandling: reflect?.isUrgentHandling || '',
      ReflectionStatusId: reflect?.reflectionStatusId || '',
      ProcessingStepCurrentId: reflect?.processingStepCurrentId || '',
      files: reflect?.reflectionFilesViewDtos || [],
      deletedFiles: [],
      SourceOfReflectionId: reflect?.SourceOfReflectionId || '',
    },
    mode: 'all',
    reValidateMode: 'onBlur',
  });
  const watchFields = watch([
    'DetectionPosition',
    'Content',
    'Longitude',
    'Latitude',
    'files',
    'deletedFiles',
  ]);
  const [dragActive, setDragActive] = React.useState(false);

  const { data: positionOptions } = useAutoSuggestAddress(
    watchFields.DetectionPosition
  );

  const onSubmit = async (values) => {
    console.log(values);
    if (values?.DetectionPosition && !values?.Longitude && !values?.Latitude) {
      ShowNotification(`Hãy chọn 1 vị trí`, NotificationMessageType.Warning);
      return;
    }
    try {
      if (!reflect) {
        await CreateReflect(values);
      } else {
        await UpdateReflect(values);
      }
      ShowNotification(
        `${!reflect ? 'Thêm' : 'Sửa'} phản ánh thành công`,
        NotificationMessageType.Success
      );
      onSuccess && onSuccess();
    } catch (error) {
      ShowNotification(
        `${!reflect ? 'Thêm' : 'Sửa'} phản ánh thất bại`,
        NotificationMessageType.Error
      );
    }
  };

  const handleSelectOnMap = async ([lng, lat]) => {
    try {
      const res = await geoCode({ location: [lat, lng] });
      const address = res?.result?.[0]?.address;
      if (address) {
        setValue('DetectionPosition', address);
        setValue('Longitude', lng?.toString());
        setValue('Latitude', lat?.toString());
      }
    } catch (error) {}
  };

  const handleFileChange = (files) => {
    const fileTypeExist = {
      image: 0,
      video: 0,
      document: 0,
    };
    const filesToAdd = [];
    for (let file of watchFields?.files) {
      if (file instanceof File && file?.type?.includes('image/')) {
        fileTypeExist.image++;
      }
      if (typeof file?.filePath === 'string' && isImage(file?.filePath)) {
        fileTypeExist.image++;
      }
      if (file instanceof File && file?.type?.includes('video/')) {
        fileTypeExist.video++;
      }
      if (typeof file?.filePath === 'string' && isVideo(file?.filePath)) {
        fileTypeExist.video++;
      }
      if (file instanceof File && isDocument(file?.name)) {
        fileTypeExist.document++;
      }
      if (typeof file?.filePath === 'string' && isDocument(file?.filePath)) {
        fileTypeExist.document++;
      }
    }
    for (let file of files) {
      if (file?.type?.includes('image/') && fileTypeExist?.image < 3) {
        filesToAdd.push(file);
        fileTypeExist.image++;
      }
      if (file?.type?.includes('video/') && fileTypeExist?.video < 1) {
        filesToAdd.push(file);
        fileTypeExist.video++;
      }
      if (isDocument(file?.name) && fileTypeExist?.document < 1) {
        filesToAdd.push(file);
        fileTypeExist.document++;
      }
    }
    setValue(`files`, [...watchFields?.files, ...filesToAdd]);
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const files = Object.values(e.dataTransfer.files)?.filter(
      (file) =>
        file?.type?.includes('image/') ||
        file?.type?.includes('video/') ||
        isDocument(file?.name)
    );
    handleFileChange(files);
  };

  useEffect(() => {
    register('Id');
    register('Content', {
      required: true,
    });
    register('DetectionPosition', {
      required: true,
    });
    register('Longitude');
    register('Latitude');
    register('ReflectionStatusId');
    register('ProcessingStepCurrentId');
    register('files');
    register('deletedFiles');
  }, []);

  const fileToRender = watchFields?.files?.map((file, id) => ({ id, file }));

  return (
    <div id='create-reflect-container'>
      <h2>Phản ánh kiến nghị</h2>
      <div className='p-4 mt-4'>
        <h3>Thông tin góp ý</h3>
        <div className='mt-4'>
          <label className='form-label'>Tiêu đề</label>
          <input
            type='text'
            className='form-control'
            ref={register({
              required: true,
            })}
            name='Title'
            placeholder='Nhập tiêu đề'
          />
          {errors?.Title && (
            <small class='text-danger'>
              <i>Tiêu đề không được để trống</i>
            </small>
          )}
        </div>
        <div class='mt-3'>
          <CKEditor
            editor={ClassicEditor}
            data={watchFields?.Content || ''}
            onChange={(event, editor) => {
              const data = editor.getData();
              setValue('Content', data);
            }}
          />
          {errors?.Content && (
            <small class='text-danger'>
              <i>Nội dung không được để trống</i>
            </small>
          )}
        </div>
        <div className='mt-4 form-check'>
          <input
            ref={register}
            name='IsUrgentHandling'
            type='checkbox'
            class='form-check-input'
            id='exampleCheck1'
          />
          <label className='form-check-label' for='exampleCheck1'>
            Phản ánh khẩn
          </label>
        </div>
      </div>
      <div className='p-4 mt-4'>
        <h3>Hình ảnh & video</h3>
        <div className='mt-4 upload-container' onDragEnter={handleDrag}>
          <div className='upload-content'>
            {/* {watchFields?.files?.length > 0 && <div className='upload-preview'>
              <PreviewImage file={watchFields?.files?.[0]} />
              <div className="close-btn" onClick={() => {
                if (watchFields?.files?.[0]?.fileId) {
                  setValue(`deletedFiles`, [...watchFields?.deletedFiles, watchFields?.files?.[0]])
                }
                setValue(`files`, [])
              }}>X</div>
            </div>} */}
            <img className='' src='/assets/reflect/upload.png' alt='' />
            <p>
              Kéo hoặc thả file để tải file lên hoặc{' '}
              <label htmlFor='create-reflect-upload-file'>thêm file</label>
            </p>
          </div>
          {dragActive && (
            <div
              className='drag-overlay'
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
        </div>
        <input
          type='file'
          id='create-reflect-upload-file'
          className='form-control'
          hidden
          onChange={(e) => handleFileChange(e.target.files)}
          multiple
          accept='image/*,video/*,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf'
        />
        <div className='preview-list mt-3'>
          {fileToRender
            ?.filter(
              (item) => !isDocument(item?.file?.name || item?.file?.fileName)
            )
            ?.map((item, i) => (
              <div className='preview-item'>
                <PreviewImage file={item?.file} key={i} />
                <div
                  className='close-btn'
                  onClick={() => {
                    if (item?.file?.fileId) {
                      setValue(`deletedFiles`, [
                        ...(watchFields?.deletedFiles || []),
                        item?.file,
                      ]);
                    }
                    const filesClone = [...watchFields?.files];
                    filesClone.splice(item?.id, 1);
                    setValue(`files`, filesClone);
                  }}
                >
                  X
                </div>
              </div>
            ))}
        </div>
        <div className='preview-list mt-3'>
          {fileToRender
            ?.filter((item) =>
              isDocument(item?.file?.name || item?.file?.fileName)
            )
            ?.map((item, i) => (
              <div className='preview-item'>
                <PreviewImage file={item?.file} key={i} />
                <div
                  className='close-btn'
                  onClick={() => {
                    if (item?.file?.fileId) {
                      setValue(`deletedFiles`, [
                        ...(watchFields?.deletedFiles || []),
                        item?.file,
                      ]);
                    }
                    const filesClone = [...watchFields?.files];
                    filesClone.splice(item?.id, 1);
                    setValue(`files`, filesClone);
                  }}
                >
                  X
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className='p-4 mt-4'>
        <h3>Vị trí phản ánh</h3>
        <div className='mt-4'>
          <Autocomplete
            disabled={false}
            options={positionOptions}
            getOptionLabel={(option) => option?.address || ''}
            id='controlled'
            noOptionsText='Không có lựa chọn'
            isOptionEqualToValue={(option, value) => option === value}
            // loading={autoSuggestPoistions?.isRunning() || detectionPositionSearchTerm !== detectionPositionDebounce}
            onChange={(e, value) => {
              setValue('DetectionPosition', value?.address);
              setValue('Longitude', value?.location?.lng?.toString());
              setValue('Latitude', value?.location?.lat?.toString());
            }}
            renderInput={(params) => {
              return (
                <div ref={params.InputProps.ref}>
                  <input
                    {...params.inputProps}
                    type='text'
                    placeholder={`--Nhập vị trí--`}
                    className={'form-control'}
                    value={watchFields.DetectionPosition}
                    onChange={(e) => {
                      setValue('DetectionPosition', e.target.value);
                      setValue('Longitude', '');
                      setValue('Latitude', '');
                    }}
                  />
                </div>
              );
            }}
          />
          {errors?.DetectionPosition && (
            <small class='text-danger'>
              <i>Vị trí không được để trống</i>
            </small>
          )}
          <div>
            <OpenlayersMapView
              lng={+watchFields?.Longitude}
              lat={+watchFields?.Latitude}
              onSelect={handleSelectOnMap}
            />
          </div>
        </div>
      </div>
      <button
        type='submit'
        className='mt-4'
        disabled={isSubmitting}
        onClick={handleSubmit(onSubmit)}
      >
        {!reflect ? 'Gửi' : 'Cập nhật'} phản hồi
      </button>
    </div>
  );
}

export default CreateReflectPage;

function PreviewImage({ file, className }) {
  const [result, setResult] = useState('');
  const [name, setName] = useState('');
  const [type, setType] = useState('');

  useEffect(() => {
    if (file instanceof File && file?.type?.includes('image/')) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setResult(reader.result);
        setType('image');
      };
      reader.onerror = function (error) {
        console.log('Error when preview image: ', error);
      };
    }
    if (file instanceof File && file?.type?.includes('video/')) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setResult(reader.result);
        setType('video');
      };
      reader.onerror = function (error) {
        console.log('Error when preview video: ', error);
      };
    }
    if (file instanceof File && isDocument(file?.name)) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setResult(reader.result);
        setName(file?.name);
        setType('document');
      };
      reader.onerror = function (error) {
        console.log('Error when preview video: ', error);
      };
    }
    if (typeof file?.filePath === 'string' && isImage(file?.filePath)) {
      setResult(BASE_IMAGE + file?.filePath);
      setType('image');
    }
    if (typeof file?.filePath === 'string' && isVideo(file?.filePath)) {
      setResult(BASE_IMAGE + file?.filePath);
      setType('video');
    }
    if (typeof file?.filePath === 'string' && isDocument(file?.filePath)) {
      setResult(BASE_IMAGE + file?.filePath);
      setName(file?.fileName);
      setType('document');
    }
  }, [file]);

  if (type === 'image') {
    return <img src={result} className={className} alt='' />;
  }
  if (type === 'video') {
    return (
      <video preload='metadata' controls>
        <source src={`${result}#t=0.1`} type='video/mp4' />
      </video>
    );
  }
  if (type === 'document') {
    return (
      <a className='document' href={result} target='_blank'>
        <p></p>
        <FontAwesomeIcon icon={faFile} />
        <p>{name}</p>
      </a>
    );
  }

  return <></>;
}
