import React, { useState, useEffect } from 'react'
import { isImage, isVideo, isDocument } from 'utils/file';
import { BASE_IMAGE } from '../reflect-card/reflect-card.view';
import { api } from 'api/api-config'

export default function PreviewImage({ file, className, disableControlOnVideo = false }) {
  const [result, setResult] = useState('');
  const [name, setName] = useState('');
  const [type, setType] = useState('');

  useEffect(() => {
    if (file instanceof File && file?.type?.includes('image/')) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setResult(reader.result);
        setType('image');
      };
      reader.onerror = function (error) {
        console.log('Error when preview image: ', error);
      };
    }
    if (file instanceof File && file?.type?.includes('video/')) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setResult(reader.result);
        setType('video');
      };
      reader.onerror = function (error) {
        console.log('Error when preview video: ', error);
      };
    }
    if (file instanceof File && isDocument(file?.name)) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setResult(reader.result);
        setName(file?.name);
        setType('document');
      };
      reader.onerror = function (error) {
        console.log('Error when preview video: ', error);
      };
    }
    if (typeof file?.filePath === 'string' && isImage(file?.filePath)) {
      setResult(BASE_IMAGE + file?.filePath);
      setType('image');
    }
    if (typeof file?.filePath === 'string' && isVideo(file?.filePath)) {
      setResult(BASE_IMAGE + file?.filePath);
      setType('video');
    }
    if (typeof file?.filePath === 'string' && isDocument(file?.filePath)) {
      setResult(BASE_IMAGE + file?.filePath);
      setName(file?.fileName);
      setType('document');
    }
  }, [file]);

  if (type === 'image') {
    return <img src={result} className={className} alt='' />;
  }
  if (type === 'video') {
    return (
      <video preload='metadata' controls={!disableControlOnVideo}>
        <source src={`${result}#t=0.1`} type='video/mp4' />
      </video>
    );
  }
  if (type === 'document') {
    return (
      <a className='document' href={result} target='_blank'>
        <p></p>
        {/* <FontAwesomeIcon icon={faFile} /> */}
        <p>{name}</p>
      </a>
    );
  }

  return <></>;
}
