import React, { useState } from 'react'
import SlickSlider from "react-slick";
import { BASE_IMAGE } from '../reflect-card/reflect-card.view';
import PreviewMedia from '../media/preview-media.view'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

const GridView = ({ files }) => {
  const [isOpenGallery, setOpenGallery] = useState(false)

  const filesToRender = Array.isArray(files) ? [...files] : []

  const classNameToRender = [
    '',
    'grid-view__one-item',
    'grid-view__two-item',
    'grid-view__three-item',
    'grid-view__four-item',
    'grid-view__five-item'
  ]

  const slick = <div>
    <SlickSlider>
      {filesToRender?.map((item, i) =>
        <div>
          <PreviewMedia file={item} key={i} />
        </div>
      )}
    </SlickSlider>
  </div>


  const renderFiles = () => <div className={`grid-view ${classNameToRender[filesToRender?.length]}`} onClick={() => setOpenGallery(true)}>
    {filesToRender?.map((item, i) =>
      <div>
        <PreviewMedia file={item} disableControlOnVideo key={i} />
      </div>
    )}
  </div>

  const renderMoreFiveFiles = () => <div className='grid-view grid-view__five-item' onClick={() => setOpenGallery(true)}>
    {filesToRender?.slice(0, 5)?.map((item, i) =>
      <div key={i}>
        <PreviewMedia disableControlOnVideo file={item} />
        {i === 4 && (
          <div className='remain-image'>
            +{filesToRender?.length - 4}
          </div>
        )}
      </div>
    )}
  </div>

  return <>
    {filesToRender?.length <= 5 ? renderFiles() : renderMoreFiveFiles()}
    {isOpenGallery && <div className="gallery-wrapper">
      <div className='close-btn' onClick={() => setOpenGallery(false)}>
        <FontAwesomeIcon icon={faWindowClose} />
      </div>
      <div>
        <SlickSlider>
          {filesToRender?.map((item, i) =>
            <div className="gallery-item">
              <PreviewMedia file={item} key={i} />
            </div>
          )}
        </SlickSlider>
      </div>
    </div>}
  </>
}

export default GridView

