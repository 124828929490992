import React from "react";
import "./general-planning.scss";
import TopRightPanelView from "../planning-map-view/top-right-panel/top-right-panel.view";
import LeftMenuView from "./left-menu/left-menu.view";
import OpenlayersMapView from "../openlayers-map/openlayers-map.view";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as MapDataStore from "../../redux/store/map-data/map-data.store";
import { GeneralPlanningViewProps, GeneralPlanningViewState, } from "../../models/general-planning-view/props-state.model";
import {isMobile} from 'react-device-detect';
import MapToolsPanel from "../planning-map-view/map-tools-panel/map-tools-panel.view";
import { SetDisplayMaptoolPanel } from "redux/store/open-layer/map-tools-panels.store";
// ./map-tools-panel/map-tools-panel.view.jsx

const ListBaseMap: any[] = [
  {
    base_map_setting_id: 0,
    id: 1,
    map_setting_id: 1,
    url: "https://mt0.google.com/vt/lyrs=m&hl=vi&x={x}&y={y}&z={z}",
    view_default: true,
    z_index: 0,
    name: "Bản đồ nền đường phố",
    baseMapSettingModel: {
      id: 0,
      layer_type: "",
      status: true,
      url: "",
    },
  },
  {
    base_map_setting_id: 0,
    id: 1,
    map_setting_id: 1,
      url: "https://mt0.google.com/vt/lyrs=y&hl=vi&x={x}&y={y}&z={z}",
    view_default: true,
    z_index: 0,
    name: "Bản đồ vệ tinh",
    baseMapSettingModel: {
      id: 0,
      layer_type: "",
      status: true,
      url: "",
    },
  },
  {
    base_map_setting_id: 0,
    id: 1,
    map_setting_id: 1,
    url: "",
    view_default: true,
    z_index: 0,
    name: "Không nền",
    baseMapSettingModel: {
      id: 0,
      layer_type: "",
      status: true,
      url: "",
    },
  },
];

const fixedLayerTablename = [
  {
  tableName : 'boundaries',
  isAddedLayer : false,
  zIndex: 10,
  },
  {
    tableName : 'label_boundaries',
    isAddedLayer : false,
    zIndex: 15,
  },
  {
    tableName : 'diaphantinh_wgs84',
    isAddedLayer : false,
    zIndex: 5,
  }
];
class GeneralPlanningView extends React.Component<GeneralPlanningViewProps, GeneralPlanningViewState> {
  constructor(props: GeneralPlanningViewProps) {
    super(props);
    this.state = {
      isLeftNavbarHide: false,
      modalHeightStyle: 0,
      isShowMapToolsPanel: false,
      isShowFilterInfomationPopup: false,
      isDisplaySearchResult: false,
      displaySearchLocationBar: false,
      coordinate: "",
      fixedLayerTablename: fixedLayerTablename,
      isDoneConstructMap: false,
    };
    this.openFilterInfomationPopup = this.openFilterInfomationPopup.bind(this);
    this.closeFilterInfomationPopup = this.closeFilterInfomationPopup.bind(this);
  }

  componentDidMount() {
    this.setState({
      modalHeightStyle: window.innerHeight - 100,
    });
    this.props.GetAllPlanning();
    this.props.GetAllPlanningCategoriesType();
    // this.props.GetAllPlanningBoundaries();
    
    this.props.SetDisplayMaptoolPanel(false);

    if(isMobile){
      this.handleShowHidePlanningMenu();
    }
  }

  handleClickIconLeftNavbar(id: number) {
    this.handleClickToggleLeftNavBar();
    const buttonElement = document.querySelector(
      `button[aria-controls="${id}"]`
    ) as HTMLElement;
    if (buttonElement?.classList.contains("collapsed")) buttonElement.click();
  }

  handleClickToggleLeftNavBar() {
    this.setState({
      isLeftNavbarHide: !this.state.isLeftNavbarHide,
    });
    if (!this.state.isLeftNavbarHide) {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.add("hide-left-navbar");
    } else
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.remove("hide-left-navbar");
  }

  openFilterInfomationPopup() {
    this.setState({
      isShowFilterInfomationPopup: true,
    });
  }

  closeFilterInfomationPopup() {
    this.setState({
      isShowFilterInfomationPopup: false,
    });
  }

  manualCloseMaptoolsFilterInfomationPopup = () => {
    this.setState({ isShowFilterInfomationPopup: false });
  };

  handleOpenOrCloseMapToolsPanel = () => {
    this.setState({
      isShowMapToolsPanel: !this.state.isShowMapToolsPanel,
    });
  };

  handleShowHidePlanningMenu() {
    const LeftMenuElement = document.getElementsByClassName(
      "left-custom-navbar"
    )[0] as HTMLElement;
    if (LeftMenuElement) {
      if (this.state.isLeftNavbarHide) {
        LeftMenuElement.style.left = "0";
      } else {
        LeftMenuElement.style.left = "-320px";
      }
      this.setState({ isLeftNavbarHide: !this.state.isLeftNavbarHide });
    }
  }
  
  componentDidUpdate(prevProps:any, prevState:any) {
    if (
      this.state.isDoneConstructMap != prevState.isDoneConstructMap
    ) {
      if(this.state.isDoneConstructMap) {
        const temp = [...this.state.fixedLayerTablename];
        temp.forEach((item)=>{
          console.log("check item", item);
          
          this.props.openLayerController.addLayerManually(item.tableName, item.isAddedLayer, item.zIndex);
          item.isAddedLayer = true;
        })
        this.setState({
          fixedLayerTablename : temp
        })
      }
      this.props.openLayerController.handleViewCurrentLocaltion();
    }
  }

  componentWillUnmount () {
    this.props.SetDisplayMaptoolPanel(false);
  }

  setIsDoneConstructMap = (value : boolean) => {
    this.setState({
      isDoneConstructMap : value
    })
  }

  render() {
    const isLeftNavbarHide = this.state.isLeftNavbarHide;

    return (
      <div className="general-planning-map-view-container position-relative">
        {/* Top right panel*/}
        <TopRightPanelView
          isHiddenMapTools={false}
          baseMapList={ListBaseMap}
          isHiddenSearch={true}
          isHiddenLayer={true}
          isHiddenInfor={true}
        />

        <div className="position-absolute map-tools-container">
          <MapToolsPanel
            isHiddenFilterInfo = {true}
            isHiddenSelectAnObject = {true}
            isHiddenSelectMultiObject = {true}
            isHiddenPrintTheMap = {true}
            isHiddenExportMap = {true}
            isHiddenMoveViewport = {true}
          />
        </div>

        {/* Open layer view port */}
        <OpenlayersMapView
          projection={"EPSG:4326"}
          isGeneralPlanning={true}
          coordinate={this.props.location}
          setIsDoneConstructMap={this.setIsDoneConstructMap}
        />

        {/* Left nav bar */}
        <div className="left-custom-navbar position-absolute h-100">
          {/* <SelectCurrentLayerView listLayers={ExtracAllLayersArrays(this.props.mapData)} /> */}
          <button
              onClick={() => this.handleShowHidePlanningMenu()}
              className="custom-button-left-menu btn position-absolute"
              title="Các lớp quy hoạch"
          >
            <img
                src={require(isLeftNavbarHide
                    ? "../../assets/icon/layer.svg"
                    : "../../assets/icon/closeX.svg")}
                alt="Close"
            />
          </button>
          <LeftMenuView
              resetSearchDisplayMode={() => this.setState({ isDisplaySearchResult: false })}
              isDisplaySearchResult={this.state.isDisplaySearchResult}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  hasLoadedMapData: state.mapData.hasLoadingData,
  mapData: state.mapData.mapData,
  planningRelationShips: state.mapData.planningRelationShips,
  listPlanning: state.mapData.listPlanning,
  listPlanningCategoriesAll: state.mapData.listPlanningCategoriesAll,
  openLayerController: state.openlayer.openLayerController,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      SetDisplayMaptoolPanel: SetDisplayMaptoolPanel,
      GetMapDetail: MapDataStore.GetMapDetailByPlanningId,
      GetAllPlanningCategoriesType: MapDataStore.GetAllPlanningCategoriesType,
      GetAllPlanning: MapDataStore.GetAllPlanning,
      GetAllPlanningBoundaries: MapDataStore.GetAllPlanningBoundaries,
      SaveHeaderForTable: MapDataStore.SaveHeaderForTable,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralPlanningView);
