import { set } from 'immutable';
import { useEffect, useState } from 'react';
import {
  Categories,
  DeleteReflect,
  ReflectByCategories,
  ReflectDetail,
} from '../../../redux/store/category/category.store';
import { GetAllComment } from '../../../redux/store/reflect/reflect-comments.store';
import {
  GetMyListReflect,
  GetReflectListHomePage,
} from '../../../redux/store/reflect/reflect.store';

export const useGetReflectListHomePage = () => {
  const [reflectByCategory, setReflectByCategory] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    handleGetReflectListHomePage();
  }, []);

  const handleGetReflectListHomePage = async () => {
    setLoading(true);
    const response = await GetReflectListHomePage();
    setReflectByCategory(response?.content || []);
    setLoading(false);
  };

  return {
    reflectByCategory,
    isLoading,
  };
};

export const useGetCategories = () => {
  const [listCategories, setListCategories] = useState([]);

  useEffect(() => {
    handleGetCategories();
  }, []);

  const handleGetCategories = async () => {
    const response = await Categories();
    setListCategories(response?.content || []);
  };

  return {
    listCategories,
    data: listCategories,
  };
};

export const useGetReflectByCategories = (categories) => {
  const [listCategoryAndReflect, setListCategoryAndReflect] = useState([]);

  useEffect(() => {
    if (categories?.length > 0) {
    }
    handleGetCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const handleGetCategories = async () => {
    const arrayResponse = await Promise.all(
      categories?.map((category) =>
        ReflectByCategories(
          `CategoriesId=${category?.id}&IsDelete=false&Sorting=createdDate desc`
        )
      )
    );
    const listCategoryAndReflect = [];
    if (
      arrayResponse?.length > 0 &&
      arrayResponse?.length === categories?.length
    ) {
      categories.forEach((category, index) => {
        listCategoryAndReflect.push({
          ...category,
          reflects: arrayResponse?.[index]?.content?.items,
        });
      });
    }
    setListCategoryAndReflect(listCategoryAndReflect);
  };

  return {
    listCategoryAndReflect,
  };
};

export const useGetReflects = () => {
  const [listReflects, setListReflects] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    handleGetReflects();
  }, []);

  const handleGetReflects = async () => {
    setLoading(true);
    const response = await ReflectByCategories(
      'Sorting=createdDate desc&PageIndex=1&PageSize=10'
    );
    setLoading(false);
    setListReflects(response?.content?.items || []);
  };

  return {
    listReflects,
    isLoading,
  };
};

export const useGetReflectsByCategoryId = (categoryId) => {
  const [listReflects, setListReflects] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(1);

  useEffect(() => {
    if (categoryId) {
      handleGetReflects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, page]);

  const handleGetReflects = async () => {
    setLoading(true);
    try {
      const response = await ReflectByCategories(
        `CategoriesId=${categoryId}&Sorting=createdDate desc&PageIndex=${page}&PageSize=10`
      );
      setListReflects(response?.content?.items || []);
      setTotal(response?.content?.totalItemCount || 0);
      setTotalPage(response?.content?.pageCount);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    listReflects,
    total,
    setPage,
    isLoading,
    page,
    totalPage,
  };
};

export const useGetReflectDetail = (reflectId) => {
  const [reflectDetail, setReflectDetail] = useState({});

  useEffect(() => {
    if (reflectId) {
      handleGetReflectDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reflectId]);

  const handleGetReflectDetail = async () => {
    const response = await ReflectDetail(`id=${reflectId}`);
    setReflectDetail(response?.content || {});
  };

  return {
    reflectDetail,
  };
};

export const useGetSimilarReflect = (categoryId) => {
  const [similarReflect, setSimilarReflect] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (categoryId) {
      handleGetSimilarReflect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  const handleGetSimilarReflect = async () => {
    setLoading(true);
    const response = await ReflectByCategories(
      `CategoriesId=${categoryId}&Sorting=createdDate desc`
    );
    setSimilarReflect(response?.content?.items || []);
    setLoading(false);
  };

  return {
    similarReflect,
    isLoading,
  };
};

export const useGetMyListReflect = (params) => {
  const [data, setData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);

  const clearParams = () => {
    const newParams = { ...params };
    if (!newParams?.Search) {
      delete newParams?.Search;
    } else {
      newParams.Search = newParams?.Search?.trim();
    }
    if (!newParams?.Sorting) {
      delete newParams?.Sorting;
    } else {
      newParams.Sorting = newParams?.Sorting;
    }
    newParams.PageSize = 20;
    newParams.PageIndex = pageIndex;
    return newParams;
  };

  useEffect(() => {
    handleGetMyListReflect(clearParams(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(params), isRefresh, pageIndex]);

  const handleGetMyListReflect = async (params) => {
    setLoading(true);
    const response = await GetMyListReflect(params);
    setData(response?.content?.items || 0);
    setTotalItem(response?.content?.totalItemCount || 0);
    setTotalPage(response?.content?.pageCount || 0);
    setLoading(false);
  };

  return {
    data,
    refresh: handleGetMyListReflect,
    setPageIndex,
    pageIndex,
    totalItem,
    totalPage,
    isLoading,
    setIsRefresh,
    isRefresh,
  };
};

export const useDeleteReflect = () => {
  const [loading, setLoading] = useState(false);
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [selectedReflect, setSelectedReflect] = useState({});

  const handleDeleteReflect = async (id) => {
    setLoading(true);
    const response = await DeleteReflect({ id });
    setLoading(false);
    return Promise.resolve(response);
  };
  return {
    onDeleteReflect: handleDeleteReflect,
    isShowConfirm,
    setIsShowConfirm,
    loading,
    setSelectedReflect,
    selectedReflect,
  };
};

export const useGetListComment = (reflectId) => {
  const [comments, setComments] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalComment, setTotalComment] = useState(0);
  const [totalCommentPage, setTotalCommentPage] = useState(0);
  const [totalItemOriginalCount, setTotalItemOriginalCount] = useState(0);

  const handleGetAllComment = async () => {
    const response = await GetAllComment({
      PageIndex: pageIndex,
      PageSize: 10,
      IsActive: true,
      IsDelete: false,
      ReflectionId: reflectId,
    });
    setComments(response?.content?.items || []);
    setTotalComment(response?.content?.totalItemCount || 0);
    setTotalCommentPage(response?.content?.pageCount || 0);
    setTotalItemOriginalCount(response?.content?.totalItemOriginalCount || 0);
  };

  useEffect(() => {
    if (reflectId) {
      handleGetAllComment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reflectId, pageIndex]);

  return {
    comments,
    pageIndex,
    setPageIndex,
    totalComment,
    totalCommentPage,
    refreshComment: handleGetAllComment,
    totalItemOriginalCount,
  };
};
