import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./../auth.scss";
import { faArrowLeft, faUser, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export const ForgotPassword = (props) => {
  const { register, errors, setIsTabForgotPassword, setIsTabLogin, clearErrors, isForgotSuccess, onHide } = props;

  const onOpenFormLogin = () => {
    setIsTabForgotPassword(false);
    setIsTabLogin(true);
  }

  useEffect(() => {
    clearErrors();
  }, [])

  return (
    <div className="col-12 form-group mt-4 auth-container">
      <div className="row forgot">
        <div className="col-12 text-center">
          <img src="./user.png" />
          <h3 className="title text-center w-100 mt-2 font-weight-bold text-uppercase">Quên mật khẩu</h3>
          {isForgotSuccess &&
            <>
              <span className='text-secondary'>Một tin nhắn đặt lại mật khẩu đã được gửi tới email của bạn. Vui lòng kiểm tra!</span>
              <div className="col-12 text-center mb-4 mt-2" onClick={onHide}>
                <button className="btn btn-submit w-100 text-uppercase text-light">
                  Trang chủ
                </button>
              </div>
            </>
          }
        </div>
        {!isForgotSuccess &&
          <>
            <div className="col-12 form-group mt-2 mb-4">
              <FontAwesomeIcon icon={faUser} className="icon-input" />
              <input
                type="text"
                name="email"
                className="form-control"
                placeholder="Email"
                ref={register({
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors.email && errors.email.type === "required" && (
                <span className="error">Trường này là bắt buộc</span>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <span className="error">Email không đúng định dạng</span>
              )}
            </div>
            <div className="col-6 text-left">
              <Link className="forgot-password" onClick={onOpenFormLogin}>
                <FontAwesomeIcon icon={faArrowLeft} className="icon-link" />{" "}
                Quay lại
              </Link>
            </div>
            <div className="col-6 pl-5 text-right">
              <button type="submit" className="btn btn-submit text-light w-100">
                Tiếp theo
              </button>
            </div>
          </>}
      </div>
    </div>
  )
}