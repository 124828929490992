import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import * as accountAction from "../../redux/store/account/account.store";
import * as viVN from "../../languages/vi-VN.json";
import "./change-password.scss";
import {
  getUserInfo,
  NotificationMessageType,
} from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

export const ChangePassword = () => {
  const [oldPasswordShown, setOldPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    getValues,
    watch,
  } = useForm({ mode: "all", reValidateMode: "onBlur" });

  const password = useRef({});
  password.current = watch("newPassword", "");

  const onChangePassword = () => {
    const { newPassword, confirmPassword } = getValues();
    newPassword === confirmPassword
      ? clearErrors(["confirmPassword"])
      : setError("confirmPassword", { type: "validate" });
  };

  const onSubmit = (values) => {
    const userInfo = getUserInfo();
    const payload = {
      userId: userInfo.userId,
      currentPassword: values.oldPassword,
      newPassword: values.newPassword,
    };

    accountAction
      .ChangeUserPassword(payload)
      .then((res) => {
        if (res && res.content) {
          ShowNotification(
            viVN.Success.ResetPasswordSuccess,
            NotificationMessageType.Success
          );
        }
      })
      .catch((err) => {
        ShowNotification(
          viVN.Errors[(err && err.errorType) || "UnableHandleException"],
          NotificationMessageType.Error
        );
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="change-password ml-2">
        <h4 className="user-info-title row">Đổi mật khẩu</h4>
        <div className="row mt-2">
          <div className="col-sm-12">
            <div className="tab-info p-4">
              <div className="form-group row">
                <label className="col-lg-3 col-form-label text-left">
                  Nhập mật khẩu cũ <span className="required"></span>
                </label>
                <div className="col-lg-9 text-left">
                  <FontAwesomeIcon
                    icon={oldPasswordShown ? faEyeSlash : faEye}
                    className="icon-input-password"
                    onClick={() => setOldPasswordShown(!oldPasswordShown)}
                  />
                  <input
                    ref={register({
                      required: true,
                      minLength: 8,
                      pattern:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/,
                    })}
                    type={oldPasswordShown ? "text" : "password"}
                    name="oldPassword"
                    className="form-control input-info"
                    placeholder="Vui lòng nhập mật khẩu cũ"
                  />
                  {errors.oldPassword &&
                    errors.oldPassword.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  {errors.oldPassword &&
                    errors.oldPassword.type === "minLength" && (
                      <span className="error">Mật khẩu tối thiểu 8 ký tự</span>
                    )}
                  {errors.oldPassword &&
                    errors.oldPassword.type === "pattern" && (
                      <span className="error">{viVN.Errors.ErrorPassword}</span>
                    )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-3 col-form-label text-left">
                  Nhập mật khẩu mới <span className="required"></span>
                </label>
                <div className="col-lg-9 text-left">
                  <FontAwesomeIcon
                    icon={newPasswordShown ? faEyeSlash : faEye}
                    className="icon-input-password"
                    onClick={() => setNewPasswordShown(!newPasswordShown)}
                  />
                  <input
                    ref={register({
                      required: true,
                      minLength: 8,
                      pattern:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/,
                    })}
                    onChange={onChangePassword}
                    type={newPasswordShown ? "text" : "password"}
                    name="newPassword"
                    className="form-control input-info"
                    placeholder="Vui lòng nhập mật khẩu mới"
                  />
                  {errors.newPassword &&
                    errors.newPassword.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  {errors.newPassword &&
                    errors.newPassword.type === "minLength" && (
                      <span className="error">Mật khẩu tối thiểu 8 ký tự</span>
                    )}
                  {errors.newPassword &&
                    errors.newPassword.type === "pattern" && (
                      <span className="error">{viVN.Errors.ErrorPassword}</span>
                    )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-3 col-form-label text-left">
                  Xác nhận mật khẩu <span className="required"></span>
                </label>
                <div className="col-lg-9 text-left">
                  <FontAwesomeIcon
                    icon={confirmPasswordShown ? faEyeSlash : faEye}
                    className="icon-input-password"
                    onClick={() =>
                      setConfirmPasswordShown(!confirmPasswordShown)
                    }
                  />
                  <input
                    ref={register({
                      validate: (value) =>
                        value === password.current ||
                        "Mật khẩu không trùng khớp",
                    })}
                    type={confirmPasswordShown ? "text" : "password"}
                    name="confirmPassword"
                    className="form-control input-info"
                    placeholder="Vui lòng nhập xác nhận mật khẩu"
                  />
                  {errors.confirmPassword &&
                    errors.confirmPassword.type === "validate" && (
                      <span className="error">Mật khẩu không khớp</span>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-7 ml-auto mr-5">
            <button
              type="submit"
              className="btn btn-submit text-uppercase text-light"
            >
              Thực hiện
            </button>
          </div>
        </div>
      </div>
    </Form>
  );
};
