import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./select-current-layer.scss";
import LayerSettingsModels from "../../../models/map-data-model-b/layer-setting-models";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import OpenlayerController from "../../../models/open-layer/openlayer-controller-custom.model";
import LayerCategoryModels from "models/map-data-model-b/layer-category-models";
import * as MapToolDataStore from "../../../redux/store/map-tool/map-tool.store";

interface LayerSettingsModelExtends
  extends LayerSettingsModels.LayerSettingsModel {
  label: string;
  value: string;
  opacity: string;
}

interface InitProps {
  listLayers: LayerSettingsModels.LayerSettingsModel[];
  openLayerController?: OpenlayerController;
  dataToggleLayer?: LayerCategoryModels.LayerCategoryModel[];
  UpdateDataToggleLayer?: (mapToolData: any) => any;
}

function AddLabelValueToListLayers(
  listLayer: LayerSettingsModels.LayerSettingsModel[]
): LayerSettingsModelExtends[] {
  const result: LayerSettingsModelExtends[] = [];
  listLayer.map((layerObject) =>
    result.push({
      ...layerObject,
      label: layerObject.name,
      value: String(layerObject.id),
      opacity: "100",
    })
  );
  return result;
}

function SelectCurrentLayerView(props: InitProps) {
  const [layer, setLayer] = useState<any>();
  const [listLayers, setListLayers] = useState<LayerSettingsModelExtends[]>([]);

  useEffect(() => {
    if (props.listLayers && props.listLayers.length > 0) {
      let layers = AddLabelValueToListLayers(props.listLayers);
      // setLayer(layers[0]);
      setListLayers(layers);
    }
  }, [props.listLayers]);

  useEffect(()=> {
    return()=>{
      props.openLayerController?.resetLayerOpacity();
      console.log("UNMOUNT CALL");
    }
  },[])

  useEffect(()=> {
    if(!layer || !layer.opacity) return;
    let opacity = Number(layer.opacity) / 100;
    props.openLayerController?.changeCurrentLayerOpacity(opacity, layer);
  },[layer])

  const handleChangeCurrentLayer = (
    selectedObject: LayerSettingsModelExtends | any,
    actionDetail: any
  ) => {
    setLayer(selectedObject);

    if ( !selectedObject.is_check ) {
      selectedObject.is_check = !selectedObject.is_check;
      props.UpdateDataToggleLayer && props.UpdateDataToggleLayer(selectedObject);
    }
    
    props.openLayerController?.changeCurrentLayer(selectedObject);
    props.openLayerController?.resetLayerOpacity();
  };

  const handleChangeOpacityOfCurrentLayer = (event: any) => {
    let data = {
      ...layer,
      opacity: event.target.value,
    };

    // const dataList = listLayers.map((item) => {
    //   if (item.id === data.id) {
    //     item.opacity = data.opacity;
    //   }
    //   return item;
    // });
    // let opacity = Number(data.opacity) / 100;
    // props.openLayerController?.changeCurrentLayerOpacity(opacity, layer);
    // setListLayers(dataList);
    setLayer(data);
  };

  if (listLayers.length === 0) return null;

  return (
    <div id="map-tool-panel-right-base-map"  className="position-relative select-base-map-container">
      <div  className="position-absolute select-base-map bg-white pt-4 pl-2 pr-2 pb-2 rounded">
        <div className="border-top pt-2">
          <label className="text-secondary">Chọn lớp cơ sở:</label>
          <Select
            defaultValue={listLayers && listLayers[0]}
            onChange={(selected, action) =>
              handleChangeCurrentLayer(selected, action)
            }
            options={listLayers}
            placeholder="Lớp hiện hành"
          />
          <input
            id="ranger-input-current-layer"
            onChange={handleChangeOpacityOfCurrentLayer}
            className="w-100 opacity-range-control"
            type="range"
            value={layer?.opacity || 100}
            min="0"
            max="100"
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  openLayerController: state.openlayer.openLayerController,
  dataToggleLayer: state.mapToolData.dataToggleLayer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      UpdateDataToggleLayer: MapToolDataStore.UpdateDataToggleLayer,
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(SelectCurrentLayerView);
