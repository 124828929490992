import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

export const CardVertivalSkeleton = () => {
  return <Box>
    <Skeleton variant="rect" width="100%" height={118} />
    <Skeleton variant="text" width="80%" />
    <Skeleton variant="text" width="100%" />
    <Skeleton variant="text" width="60%" />
    <Skeleton variant="text" width="100%" />
  </Box>
}

export const CardHorizontalSkeleton = () => {
  return <Grid container className='row' spacing={1}>
    <Grid item xs={4}>
      <Skeleton variant="rect" width={110} height={110} />
    </Grid>
    <Grid item xs={8}>
      <Skeleton variant="text" width="80%" />
      <Skeleton variant="text" width="100%" />
      <Skeleton variant="text" width="60%" />
      <Skeleton variant="text" width="100%" />
    </Grid>
  </Grid>
}

export const ReflectHomePageSkeleton = () => {
  return <Box pb={4}>
    <Box display="flex" justifyContent="space-between" alignItems="center" pb={2} >
      <Skeleton variant="rect" width="60%" height={40} />
      <Skeleton variant="text" width="10%" />
    </Box>
    <Grid container className='row' spacing={3}>
      <Grid item xs={12} lg={6}>
        <CardVertivalSkeleton />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CardVertivalSkeleton />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CardHorizontalSkeleton />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CardHorizontalSkeleton />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CardHorizontalSkeleton />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CardHorizontalSkeleton />
      </Grid>
    </Grid>
  </Box>
}

export const ReflectByCategorySkeleton = () => {
  return <Box pb={4}>
    <Grid container className='row' spacing={3}>
      <Grid item xs={12} lg={6}>
        <CardHorizontalSkeleton />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CardHorizontalSkeleton />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CardHorizontalSkeleton />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CardHorizontalSkeleton />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CardHorizontalSkeleton />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CardHorizontalSkeleton />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CardHorizontalSkeleton />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CardHorizontalSkeleton />
      </Grid>
    </Grid>
  </Box>
}

export const ReflectAsideSkeleton = () => {
  return <Box pb={4}>
    <Box display="flex" justifyContent="space-between" alignItems="center" pb={2} >
      <Skeleton variant="rect" width="60%" height={20} />
      <Skeleton variant="text" width="10%" />
    </Box>
    <Box pb={1}>
      <CardHorizontalSkeleton />
    </Box>
    <Box pb={1}>
      <CardHorizontalSkeleton />
    </Box>
    <Box pb={1}>
      <CardHorizontalSkeleton />
    </Box>
    <Box pb={1}>
      <CardHorizontalSkeleton />
    </Box>
    <Box pb={1}>
      <CardHorizontalSkeleton />
    </Box>
    <Box pb={4}>
      <CardHorizontalSkeleton />
    </Box>
    <Box display="flex" justifyContent="space-between" alignItems="center" pb={2} >
      <Skeleton variant="rect" width="60%" height={20} />
      <Skeleton variant="text" width="10%" />
    </Box>
    <Box m="auto" pb={4}>
      <Skeleton variant="circle" width={250} height={250} />
    </Box>
    <Box display="flex" justifyContent="space-between" alignItems="center" pb={2} >
      <Skeleton variant="rect" width="60%" height={20} />
      <Skeleton variant="text" width="10%" />
    </Box>
    <Box m="auto">
      <Skeleton variant="circle" width={250} height={250}/>
    </Box>
  </Box>
}
