import Service from '../../../api/api-service-custom';
import { ApiUrl } from '../../../api/api-url';

const service = new Service();

export const Categories = (data) => {
  return service
    .get(ApiUrl.GetLookupCategories, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const CategoryDetail = (data) => {
  return service
    .get(ApiUrl.GetDetailCategories, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ReflectByCategories = (data) => {
  return service
    .get(ApiUrl.GetReflectByCategories, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const Reflects = (data) => {
  return service
    .get(ApiUrl.GetReflectByCategories, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ReflectDetail = (data) => {
  return service
    .get(ApiUrl.GetReflectDetail, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const DeleteReflect = (data) => {
  return service
    .put(`${ApiUrl.DeleteReflect}?id=${data?.id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
