import Service from '../../../api/api-service-custom';
import { ApiUrl } from '../../../api/api-url';

const service = new Service();

export const GetReflectListHomePage = (data = '') => {
  return service
    .get(ApiUrl.GetReflectListHomePage, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetSimilarReflect = (data = '') => {
  return service
    .get(ApiUrl.GetSimilarReflect, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetMyListReflect = (body = {}) => {
  const params = new URLSearchParams();
  if (typeof body === 'object') {
    for (let key in body) {
      if (body[key]) {
        params.append(key, body[key])
      }
    }
  }
  return service
    .get(ApiUrl.GetMyListReflect, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const CreateReflect = (body = {}) => {
  const formData = new FormData();
  if (typeof body === 'object') {
    let fileIndex = 0;
    for (let key in body) {
      if (Array.isArray(body[key])) {
        const arr = body[key]?.filter((item) => item !== undefined);
        if (key === 'files') {
          for (let i = 0; i < arr.length; i++) {
            if (!arr[i].fileId) {
              formData.append(`fileInfos[${fileIndex}].Action`, 'insert');
              formData.append(`fileInfos[${fileIndex}].File`, arr[i]);
              fileIndex++;
            }
          }
        } else if (key === 'deletedFiles') {
          for (let i = 0; i < arr.length; i++) {
            formData.append(`fileInfos[${fileIndex}].Action`, 'delete');
            formData.append(`fileInfos[${fileIndex}].Id`, arr[i].fileId);
            formData.append(`fileInfos[${fileIndex}].url`, arr[i].filePath);
            fileIndex++;
          }
        } else {
          for (let element of arr) {
            formData.append(key, element);
          }
        }
      } else {
        formData.append(key, body[key]);
      }
    }
  }
  formData.append('SourceOfReflectionId', '2');
  return service
    .post(ApiUrl.CreateReflect, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const UpdateReflect = (body = {}) => {
  const formData = new FormData();
  if (typeof body === 'object') {
    let fileIndex = 0;
    for (let key in body) {
      if (Array.isArray(body[key])) {
        const arr = body[key]?.filter((item) => item !== undefined);
        if (key === 'files') {
          for (let i = 0; i < arr.length; i++) {
            if (!arr[i].fileId) {
              formData.append(`fileInfos[${fileIndex}].Action`, 'insert');
              formData.append(`fileInfos[${fileIndex}].File`, arr[i]);
              fileIndex++;
            }
          }
        } else if (key === 'deletedFiles') {
          for (let i = 0; i < arr.length; i++) {
            formData.append(`fileInfos[${fileIndex}].Action`, 'delete');
            formData.append(`fileInfos[${fileIndex}].Id`, arr[i].fileId);
            formData.append(`fileInfos[${fileIndex}].url`, arr[i].filePath);
            fileIndex++;
          }
        } else {
          for (let element of arr) {
            formData.append(key, element);
          }
        }
      } else {
        formData.append(key, body[key]);
      }
    }
  }
  return service
    .put(ApiUrl.UpdateReflect, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
