import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { GetUserAccountDetail } from '../../redux/store/account/account.store';
import * as appActions from '../../core/app.store';
import * as acountAction from '../../redux/store/account/account.store';

function AppWrapper(props) {
  const location = useLocation();
  const { login, logout } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    // GetUserAccountDetail()
    //   .then((res) => {
    //     login(res?.content);
    //   })
    //   .catch((err) => {
    //     logout();
    //   });
  }, []);

  return <>{props.children}</>;
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
  isLoggedIn: state.account.isLoggedIn,
  user: state.account.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
      login: acountAction.login,
      logout: acountAction.logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
