import React from "react";
import { Link } from "react-router-dom"
import { Paper } from "@material-ui/core";
import { ChevronRight } from '@material-ui/icons';
import {
  PlaceOutlined,
  QueryBuilderOutlined,
  ChatBubbleOutlined,
} from "@material-ui/icons";
import "./header.scss";

export const ReflectHeader = ({ title, urlReadMore, type = 'standard', className = "" }) => {
  return (
    <>
      <div className={"reflect-header" + ` ${type}` + ` ${className}`}>
        <div className="reflect-header__title">{title}</div>
        {urlReadMore && <Link to={urlReadMore} className="reflect-header__read-more">Xem thêm <ChevronRight /></Link>}
      </div>
      <div className="reflect-header__divider"></div>
    </>
  );
};

export default ReflectHeader;
