/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useMediaQuery } from 'react-responsive';
import PlanningMapViewWithHooks from './planning-map-with-hooks.view';

function PlanningMapRoute(props) {
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1024px)'});
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)'});

  return (
    <PlanningMapViewWithHooks isTabletOrMobile={isTabletOrMobile} isDesktopOrLaptop={isDesktopOrLaptop} {...props}/>
  )
}

export default PlanningMapRoute;
