/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import {
  PlaceOutlined,
  QueryBuilderOutlined,
  VisibilityOutlined,
} from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { bindActionCreators } from 'redux';

import * as appActions from '../../../core/app.store';
import { dateVNFormat } from '../../../utils';

import ReflectAside from '../components/aside/aside.view';
import ReflectComment from '../components/comments/comments.view';
import ReflectHeader from '../components/header/reflect-header.view';
import ShowNotification from '../../../components/react-notifications/react-notifications';
import ReflectPageHeader from '../components/header/reflect-page-header.view';

import { CardHorizontal } from '../components/reflect-card/reflect-card.view';
import { useGetReflectDetail, useGetSimilarReflect } from '../hooks';
import './reflect-detail.scss';
import {
  CreateLevel,
  UpdateLevel,
} from '../../../redux/store/reflect/reflect-level';
import { PROCESSING_STEP_ID } from '../../../common/constants';
import { NotificationMessageType } from '../../../utils/configuration';
import { isImage, isVideo, isDocument } from 'utils/file';
import { api,apiCustom } from 'api/api-config'
export const BASE_IMAGE = apiCustom + "/";
function Reflect({ user }) {
  const { reflectInfo } = useParams();
  const re = /^(\d+)-?[a-z0-9-]*$/g;
  const reflectId = re.exec(reflectInfo)?.[1] || null;
  const { reflectDetail } = useGetReflectDetail(reflectId);
  const { similarReflect } = useGetSimilarReflect(reflectDetail?.categoriesId);
  const [satisfied, setSatisfied] = useState({
    acceptanceTotal: 0,
    satisfactionLevelId: null,
    satisfiedTotal: 0,
    unsatisfied: 0,
  });

  useEffect(() => {
    setSatisfied({
      acceptanceTotal: reflectDetail?.satisfactionLevel?.acceptanceTotal || 0,
      satisfactionLevelId:
        reflectDetail?.satisfactionLevel?.satisfactionLevelId || null,
      satisfiedTotal: reflectDetail?.satisfactionLevel?.satisfiedTotal || 0,
      unsatisfied: reflectDetail?.satisfactionLevel?.unsatisfied || 0,
    });
  }, [reflectDetail]);

  const handleClickSatisfied = (type, value) => async () => {
    if (value === satisfied?.satisfactionLevelId) {
      return ShowNotification(
        'Bạn đang chọn đánh giá này',
        NotificationMessageType.Error
      );
    }
    if (!user) {
      return ShowNotification(
        'Bạn cần đăng nhập để dùng tính năng này',
        NotificationMessageType.Error
      );
    }
    if (
      satisfied?.satisfactionLevelId !== value &&
      !satisfied?.satisfactionLevelId
    ) {
      setSatisfied({
        ...satisfied,
        satisfactionLevelId: value,
        [type]: satisfied?.[type] + 1,
      });
      await CreateLevel({
        reflectionId: reflectDetail?.id,
        satisfactionLevelId: value,
        userId: user?.id,
      });
    } else if (satisfied?.satisfactionLevelId !== value) {
      const oldSatisfyType = [
        '',
        'unsatisfied',
        'satisfiedTotal',
        'acceptanceTotal',
      ]?.[satisfied?.satisfactionLevelId];
      setSatisfied({
        ...satisfied,
        [type]: satisfied?.[type] + 1,
        [oldSatisfyType]: satisfied?.[oldSatisfyType] - 1,
        satisfactionLevelId: value,
      });
      await UpdateLevel({
        id: 0,
        reflectionId: reflectDetail?.id,
        satisfactionLevelId: value,
        userId: user?.id,
      });
    }
  };

  return (
    <>
      <ReflectPageHeader title={reflectDetail?.categoriesName} />
      <div className='container mt-4 mb-4'>
        <Grid container className='' spacing={3}>
          <Grid item xs={12} lg={8} className='reflect-detail'>
            <div className='paper p-2 p-lg-4'>
              <h1>{reflectDetail?.title}</h1>
              <div className='mt-2'>
                <span className='text-blue d-block d-md-inline'>
                  <PlaceOutlined /> {reflectDetail?.detectionPosition}
                </span>{' '}
                <span className='text-grey d-block d-md-inline'>
                  <VisibilityOutlined /> Lượt xem: {reflectDetail?.viewNum}
                </span>
              </div>
              <div className='mt-lg-2'>
                <span className='text-grey d-block d-md-inline'>
                  <QueryBuilderOutlined /> Ngày gửi:{' '}
                  {dateVNFormat(reflectDetail?.createdDate)}
                </span>{' '}
                <span className='text-grey d-block d-md-inline'>
                  <QueryBuilderOutlined /> Thời hạn xử lý:{' '}
                  {dateVNFormat(reflectDetail?.expectedEndDate)}
                </span>
              </div>
              <div className='mt-4'>
                <p
                  dangerouslySetInnerHTML={{ __html: reflectDetail?.content }}
                />
              </div>
              <div className='relfect-media-list'>
                {reflectDetail?.reflectionFilesViewDtos
                  ?.filter((item) => !isDocument(item?.fileName))
                  ?.map((item, i) => (
                    <>
                      {isImage(item?.fileName) &&(item?.processingStepId != PROCESSING_STEP_ID.HANDLED) && (
                        <div className='mt-4 text-center mx-auto' key={i}>
                          <img
                            className='d-block w-100 rounded'
                            alt=''
                            src={BASE_IMAGE + item?.filePath}
                            style={{'margin-bottom':'5px'}}
                          />
                          <i>Ảnh minh họa</i>
                        </div>
                      )}
                      {isVideo(item?.fileName)&&(item?.processingStepId != PROCESSING_STEP_ID.HANDLED) && (
                        <div className='mt-4 text-center mx-auto' key={i}>
                          <video
                            className='d-block w-100 rounded'
                            type='video/mp4'
                            controls
                            src={BASE_IMAGE + item?.filePath + '#t=0.1'}
                          />
                        </div>
                      )}
                    </>
                  ))}
                {reflectDetail?.reflectionFilesViewDtos
                  ?.filter((item) => isDocument(item?.fileName))
                  ?.map((item, i) => (
                    <>
                      <div className='mt-4' key={i}>
                        Tài liệu:{' '}
                        <a href={BASE_IMAGE + item?.filePath} target='_blank'>
                          {item?.fileName}
                        </a>
                      </div>
                    </>
                  ))}
              </div>
              {reflectDetail?.reflectionHandledViewDto && reflectDetail?.processingStepCurrentId == PROCESSING_STEP_ID.COMPLETED && (
                <>
                  <ReflectHeader title='Kết quả xử lý' className='mt-5' />
                  <h2
                    className='mt-4'
                    dangerouslySetInnerHTML={{
                      __html: reflectDetail?.provinceName,
                    }}
                  />
                  <p className='text-grey d-block d-md-inline mt-2'>
                    <QueryBuilderOutlined />{' '}
                    {dateVNFormat(reflectDetail?.expectedEndDate)}
                  </p>
                  <div className='mt-4'>
                    <div 
                      className="ck-editor" 
                      dangerouslySetInnerHTML={{__html: reflectDetail?.reflectionHandledViewDto?.reason}}
                    />
                  </div>
                  <div className='relfect-media-list'>
                {reflectDetail?.reflectionFilesViewDtos
                  ?.filter((item) => !isDocument(item?.fileName))
                  ?.map((item, i) => (
                    <>
                      {isImage(item?.fileName) &&(item?.processingStepId == 5) && (
                        <div className='mt-4 text-center mx-auto' key={i}>
                          <img
                            className='d-block w-100 rounded'
                            alt=''
                            src={BASE_IMAGE + item?.filePath}
                          />
                          <i>Ảnh minh họa</i>
                        </div>
                      )}
                      {isVideo(item?.fileName)&&(item?.processingStepId == 5) && (
                        <div className='mt-4 text-center mx-auto' key={i}>
                          <video
                            className='d-block w-100 rounded'
                            type='video/mp4'
                            controls
                            src={BASE_IMAGE + item?.filePath + '#t=0.1'}
                          />
                        </div>
                      )}
                    </>
                  ))}
                {reflectDetail?.reflectionFilesViewDtos
                  ?.filter((item) => isDocument(item?.fileName))
                  ?.map((item, i) => (
                    <>
                      <div className='mt-4' key={i}>
                        Tài liệu:{' '}
                        <a href={BASE_IMAGE + item?.filePath} target='_blank'>
                          {item?.fileName}
                        </a>
                      </div>
                    </>
                  ))}
              </div>
                  <div className='reflect-vote mt-5'>
                    <h3>Bạn đánh giá thế nào về kết quả xử lý</h3>
                    <div className='reflect-vote__action'>
                      <div
                        className='reflect-vote__icon'
                        onClick={handleClickSatisfied('satisfiedTotal', 2)}
                      >
                        <div
                          className={`love ${
                            satisfied?.satisfactionLevelId === 2 ? 'active' : ''
                          }`}
                        />
                        <p>Hài lòng ({satisfied?.satisfiedTotal || 0})</p>
                      </div>
                      <div
                        className='reflect-vote__icon'
                        onClick={handleClickSatisfied('acceptanceTotal', 3)}
                      >
                        <div
                          className={`smile ${
                            satisfied?.satisfactionLevelId === 3 ? 'active' : ''
                          }`}
                        />
                        <p>Chấp nhận ({satisfied?.acceptanceTotal || 0})</p>
                      </div>
                      <div
                        className='reflect-vote__icon'
                        onClick={handleClickSatisfied('unsatisfied', 1)}
                      >
                        <div
                          className={`sad ${
                            satisfied?.satisfactionLevelId === 1 ? 'active' : ''
                          }`}
                        />
                        <p>Không hài lòng ({satisfied?.unsatisfied || 0})</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <ReflectComment
              className='mt-4'
              reflectId={reflectId}
              totalComment={reflectDetail?.reflectionTotalComment || 0}
            />
            {similarReflect?.length > 0 && (
              <>
                <ReflectHeader title='Phản ánh liên quan' className='mt-5' />
                <Grid container spacing={2} className='mt-2'>
                  {similarReflect
                    ?.filter((reflect) => reflect?.id !== reflectDetail?.id)
                    ?.slice(0, 4)
                    .map((item, i) => (
                      <Grid item xs={12} sm={6} key={i}>
                        <CardHorizontal reflect={item} />
                      </Grid>
                    ))}
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={4} className='d-none d-lg-block'>
            <ReflectAside listReflects={similarReflect} />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
  user: state.account.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Reflect);
