import React, { useState } from "react";
import "./left-menu.scss";
import UrlCollect from "../../../common/url-collect";
import { Link } from "react-router-dom";import { connect } from "react-redux";
import PlanningCategoriesModel from "../../../models/map-data-model-b/planning-categories-model";
import OpenlayerController from "../../../models/open-layer/openlayer-controller-custom.model";
import history from "../../../common/history";
import { SearchByDistrict } from "../../look-up-planning/child-modules/planning-district/search-district.view";

interface InitLeftMenuViewProps {
  isDisplaySearchResult: boolean;
  resetSearchDisplayMode: Function;
  listPlanningCategoriesAll?: PlanningCategoriesModel[];
  openLayerController?: OpenlayerController;
  planningType: any;
  listPolygon: any;
}

function LeftMenuView(props: InitLeftMenuViewProps) {
  const [districtSelect, setDistrictSelect] = useState('');
  const [planningUnitSelect, setPlanningUnitSelect] = useState('');
  const [approvalAgencySelect, setApprovalAgencySelect] = useState('');
  const [name, setName] = useState('');
  const [yearSearch, setYearSearch] = useState(null);
  const [numberOfDecisions, setNumberOfDecisions] = useState('');
  const [constructionGroup, setConstructionGroup] = useState('');

  function handleResetDisplaySearchMode() {
    if (props.isDisplaySearchResult) props.resetSearchDisplayMode();
  }
  
  const onSubmit = () => {
    history.push(`${UrlCollect.PlanningMapViewDetail}?key=&name=${name}&district=${districtSelect}&planningUnit=${planningUnitSelect}&approvalAgency=${approvalAgencySelect}&yearSearch=${yearSearch}&numberOfDecisions=${numberOfDecisions}&constructionGroup=${constructionGroup}`)
  };
  
  return (
    <div className="left-menu-custom-container h-100 position-relative">
      <div className="search-groups">
        <SearchByDistrict
          onSubmit={onSubmit}
          setDistrictSelect={(data : any) => setDistrictSelect(data)}
          setPlanningUnitSelect={setPlanningUnitSelect}
          setApprovalAgencySelect={setApprovalAgencySelect}
          name={name}
          setName={setName}
          yearSearch={yearSearch}
          setYearSearch={setYearSearch}
          numberOfDecisions={numberOfDecisions}
          setNumberOfDecisions={setNumberOfDecisions}
          constructionGroup={constructionGroup}
          setConstructionGroup={setConstructionGroup}
        />
      </div>
      <div
        className={`title-container ${props.isDisplaySearchResult ? "" : "p-2 disabled"
          }`}
        onClick={() => handleResetDisplaySearchMode()}
      >
        Tổng hợp quy hoạch
      </div>

      {props.planningType.map((item: any) => (
        <RenderGroupPlanning
          isDisplaySearchResult={props.isDisplaySearchResult}
          titleGroup={item.name}
          key={item.id}
          urlGroup={UrlCollect.PlanningMap + "?key=" + item.id}
          listLayers={item.plannings}
        />
      ))}
    </div>
  );
}

interface InitRenderGroupPlanningProps {
  titleGroup: string;
  listLayers: any[];
  urlGroup: string;
  isDisplaySearchResult: boolean;
}

function RenderGroupPlanning(props: InitRenderGroupPlanningProps) {
  return (
    <div className="render-group-planning">
      <Link to={props.urlGroup} className="title-container p-2">
        {props.titleGroup}
      </Link>
      {props.isDisplaySearchResult &&
        props.listLayers &&
        props.listLayers.length > 0 && (
          <div className="pl-3 pr-2 pt-2 pb-2">
            {props.listLayers.map((layerObject, index) => (
              <label
                key={index}
                className="text-truncate"
                title={layerObject.name}
              >
                <input type="checkbox" /> {layerObject.name}
              </label>
            ))}
          </div>
        )}
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  listPlanningCategoriesAll: state.mapData.listPlanningCategoriesAll,
  openLayerController: state.openlayer.openLayerController,
  planningType: state.statement.planningType,
  listPolygon: state.mapData.listPolygon,
});

export default connect(mapStateToProps)(LeftMenuView);
