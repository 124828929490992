import { Typography } from '@material-ui/core';
import { Pagination, Skeleton } from '@material-ui/lab';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as appActions from '../../../core/app.store';
import { dateVNFormat, stripHtml } from '../../../utils';

import DeleteIcon from '../../../assets/icon/delete-icon.svg';
import EditIcon from '../../../assets/icon/edit-icon.svg';
import { useDeleteReflect, useGetMyListReflect } from '../hooks';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import './my-reflect.scss';
import ConfirmModal from './confirm-modal';
import EditModal from './edit-modal';
import ShowNotification from '../../../components/react-notifications/react-notifications';
import { NotificationMessageType } from '../../../utils/configuration';
import { NavLink } from 'react-router-dom';
import history from 'common/history';

const statusOptions = [
  {
    id: 1,
    name: 'Chưa xử lý',
  },
  {
    id: 2,
    name: 'Đang xử lý',
  },
  {
    id: 3,
    name: 'Đã phê duyệt',
  },
  {
    id: 4,
    name: 'Hoàn thành xử lý',
  },
];

const sortOptions = [
  { value: 'createdDate desc', label: 'Mới đến cũ' },
  { value: 'createdDate asc', label: 'Cũ nhất' },
];

const currentStepOptions = [
  { value: '', label: 'Tất cả' },
  { value: '2', label: 'Chờ phân phối' },
  { value: '3', label: 'Chờ xử lý' },
  { value: '5', label: 'Chờ phê duyệt' },
];

function MyReflect() {
  const [reflectToUpdate, setReflectToUpdate] = useState(null);
  const [searchParams, setSearchParams] = useState({
    Search: '',
    ProcessingStepCurrentId: currentStepOptions[0],
    Sorting: sortOptions?.[0],
    Page: 1,
  });

  const {
    data: myReflect,
    isLoading: isMyReflectLoading,
    setPageIndex,
    pageIndex,
    totalPage,
    totalItem,
    refresh,
  } = useGetMyListReflect({
    Sorting: searchParams?.Sorting?.value,
    ProcessingStepCurrentId: searchParams?.ProcessingStepCurrentId?.value,
  });

  const {
    onDeleteReflect,
    loading,
    setIsShowConfirm,
    isShowConfirm,
    selectedReflect,
    setSelectedReflect,
  } = useDeleteReflect();

  const handleChangeSorting = (sorting) => {
    setSearchParams({
      ...searchParams,
      Sorting: sorting,
      page: 1,
    });
  };

  const handleChangeFilter = (field, value) => {
    console.log(field, value)
    setSearchParams({
      ...searchParams,
      [field]: value,
      page: 1,
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setSearchParams({
        ...searchParams,
        Search: event?.target?.value?.trim(),
        Page: 1,
      });
    }
  };

  const handleCloseConfirmModal = () => {
    setIsShowConfirm(false);
    setSelectedReflect({});
  };

  const handleDeleteReflect = async () => {
    const response = await onDeleteReflect(selectedReflect?.id);
    if (response?.content?.status) {
      setIsShowConfirm(false);
      setSelectedReflect({});
      ShowNotification(
        'Xóa phản ánh thành công',
        NotificationMessageType.Success
      );
      // setIsRefresh(!isRefresh);
      refresh();
    }
  };

  return (
    <div className='my-reflect-container'>
      <ConfirmModal
        show={isShowConfirm}
        reflect={selectedReflect}
        onHide={handleCloseConfirmModal}
        loading={loading}
        onOk={handleDeleteReflect}
      />
      <EditModal
        show={!!reflectToUpdate}
        reflect={reflectToUpdate}
        onHide={() => setReflectToUpdate(null)}
        onSuccess={() => {
          refresh();
          setReflectToUpdate(null);
        }}
      />

      <div className='header'>
        <h2>Phản ánh của bạn</h2>
        <form className='filter'>
          <div className='search'>
            <input
              placeholder='Tìm kiếm'
              name='Search'
              onKeyPress={handleKeyPress}
            />
            <FontAwesomeIcon icon={faSearch} className='icon-search' />
          </div>
          <Select
            className='select'
            placeholder='Chọn'
            name='Sorting'
            onChange={handleChangeSorting}
            options={sortOptions}
            isSearchable={false}
            value={searchParams?.Sorting}
          />
          {/* <Select
            className='select'
            placeholder='Chọn'
            name='Trạng thái'
            onChange={(value) => handleChangeFilter('ProcessingStepCurrentId', value)}
            options={currentStepOptions}
            isSearchable={false}
            value={searchParams?.ProcessingStepCurrentId}
          /> */}
        </form>
      </div>
      <div className='table-container'>
        <table className='table table-responsive'>
          <thead>
            <tr>
              <th scope='col'>STT</th>
              <th scope='col'>Thông tin</th>
              <th scope='col' className='text-nowrap'>
                Địa chỉ
              </th>
              <th scope='col' className='text-nowrap'>
                Ngày đăng
              </th>
              <th scope='col' className='text-nowrap'>
                Trạng thái
              </th>
              <th scope='col' className='text-nowrap'>
                Hoạt động
              </th>
            </tr>
          </thead>
          {isMyReflectLoading && (
            <tbody>
              {[...Array(10)]?.map((item, i) => (
                <tr key={i}>
                  <td>
                    <Skeleton variant='text' />
                  </td>
                  <td>
                    <Skeleton variant='text' />
                  </td>
                  <td>
                    <Skeleton variant='text' />
                  </td>
                  <td>
                    <Skeleton variant='text' />
                  </td>
                  <td>
                    <Skeleton variant='text' />
                  </td>
                  <td>
                    <Skeleton variant='text' />
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {myReflect?.length > 0 && (
            <tbody>
              {myReflect?.map((item, i) => {
                const status =
                  statusOptions?.find(
                    (status) => status.id === item?.reflectionStatusId
                  ) || statusOptions?.[0];
                return (
                  <tr
                    key={i}
                    className='cursor-pointer'
                    onClick={() =>
                      history.push(`/phan-anh-hien-truong/phan-anh/${item?.id}`)
                    }
                  >
                    <td>
                      {i < 9 ? '0' : ''}
                      {i + 1}
                    </td>
                    <td>
                      <Typography variant='body2'>
                        {stripHtml(item.title)}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='body2'>
                        {stripHtml(item.detectionPosition)}
                      </Typography>
                    </td>
                    <td>{dateVNFormat(item?.createdDate)}</td>
                    <td>
                      <div
                        className={
                          item?.reflectionStatusId !== statusOptions?.[0]?.id
                            ? 'btn-blue status'
                            : 'btn-red status'
                        }
                      >
                        {status?.name}
                      </div>
                    </td>
                    <td>
                      {item?.reflectionStatusId === statusOptions?.[0]?.id && (
                        <div>
                          <button
                            className='btn-blue'
                            onClick={(e) => {
                              e.stopPropagation();
                              setReflectToUpdate(item);
                            }}
                          >
                            <img src={EditIcon} alt='' />
                          </button>
                          &nbsp;
                          <button className='btn-red'>
                            <img
                              src={DeleteIcon}
                              alt=''
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsShowConfirm(true);
                                setSelectedReflect(item);
                              }}
                            />
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
          {myReflect?.length === 0 && !isMyReflectLoading && (
            <div className='no-data'>Không có dữ liệu</div>
          )}
        </table>
      </div>
      {totalItem > 0 && (
        <div className='mt-4'>
          <Pagination
            className='reflect-pagination mt-4'
            variant='outlined'
            shape='rounded'
            page={pageIndex}
            onChange={(e, page) => page && setPageIndex(page)}
            count={totalPage}
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyReflect);
