import React, { Fragment, lazy, Suspense } from "react";
import "./planning-map-with-hooks.scss";
import ViLanguages from "../../languages/vi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import SelectCurrentLayerView from "../planning-map-view/select-current-layer/select-current-layer.view";
import OpenlayersMapView from "../openlayers-map/openlayers-map.view";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as MapDataStore from "../../redux/store/map-data/map-data.store";
import { SetDisplayMaptoolPanel, SetDisplayInfomationPopup } from "redux/store/open-layer/map-tools-panels.store";
import * as MapToolDataStore from "../../redux/store/map-tool/map-tool.store";
import PlanningMapViewModel from "../../models/planning-map-view/planning-map-view.model";
import {
  ExtractSelectedBasemap,
  ExtractCenterArray,
  ExtractExtentArray,
  ExtractListLayerArray,
  ExtracLayerListGroup,
  ExtractBasemapList,
} from "../../common/extract-information-from-map-data";
import { Link } from "react-router-dom";
import UrlCollect from "common/url-collect";
import { ToggleBottomProvider } from "./mobile/context/toggle-bottom-panel/ToggleBottomPanelContext";
import { TopRightPanelProvider } from "./mobile/context/top-right-panel/TopRightPanelContext";
import { AddContentBottomPanelProvider } from "./mobile/context/content-bottom-panel/ContentBottomPanel";
import { IsHideFilterBoxProvider } from "./mobile/context/is-hide-filter-box/IsHideFilterBox";

const LanguageCollects = ViLanguages;

//Desktop
const RenderFilterInfomationPopup = lazy(() => import("../planning-map-view/filter-infomation/filter-infomation.view.jsx"));
const MapToolsPanel = lazy(() => import("../planning-map-view/map-tools-panel/map-tools-panel.view.jsx"));
const TopRightPanelView = lazy(() => import("../planning-map-view/top-right-panel/top-right-panel.view"));
const LeftMenuView = lazy(() => import("../planning-map-view/left-menu/left-menu.view"));
const GuideUseMap = lazy(() => import("../planning-map-view/guide-use-map/guide-use-map.view"));
const Preview = lazy(() => import("../planning-map-view/left-menu/preview/preview"));

// Mobile
const TopRightPanelMobile = lazy(()=> import("./mobile/top-right-panel-mobile/top-right-panel-mobile"));
const BottomToggleMobile = lazy(()=> import("./mobile/bottom-toggle/bottom-toggle"));
const BottomPanelMobile = lazy(()=> import("./mobile/bottom-panel-mobile/bottom-panel-mobile"));
const RenderFilterInfomationPopupMobile = lazy(()=> import("./mobile/filter-infomation/filter-infomation.view.jsx"));
const GuideUseModalMobile = lazy(()=> import("./mobile/guide-use-modal/guide-use-modal"));

class PlanningMapViewWithHooks extends React.Component<
  PlanningMapViewModel.PlanningMapViewProps,
  PlanningMapViewModel.PlanningMapViewState
> {
  constructor(props: PlanningMapViewModel.PlanningMapViewProps) {
    super(props);
    this.state = {
      mapPlanning: null,
      isLeftNavbarHide: true,
      modalHeightStyle: 0,
      isShowMapToolsPanel: false,
      isShowFilterInfomationPopup: false,
      displaySearchLocationBar: false,
      planningModel: null,
      coordinate: "",
      isDoneConstructMap: false,
    };
    this.openFilterInfomationPopup = this.openFilterInfomationPopup.bind(this);
    this.closeFilterInfomationPopup =
      this.closeFilterInfomationPopup.bind(this);
  }

  setIsDoneConstructMap(data: boolean) {
    this.setState ({
      isDoneConstructMap: data
    })
  }

  componentDidMount() {
    this.setState({
      modalHeightStyle: window.innerHeight - 100,
    });
    this.props.GetMapDetail(this.props.match.params.id);
    this.props.SetDisplayMaptoolPanel(false);
    this.props.SetDisplayInfomationPopup(false);
  }

  handleClickIconLeftNavbar(id: number) {
    this.handleClickToggleLeftNavBar();
    const buttonElement = document.querySelector(
      `button[aria-controls="${id}"]`
    ) as HTMLElement;
    if (buttonElement && buttonElement.classList.contains("collapsed"))
      buttonElement.click();
  }

  handleClickToggleLeftNavBar() {
    this.setState({
      isLeftNavbarHide: !this.state.isLeftNavbarHide,
    });
    if (!this.state.isLeftNavbarHide) {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.add("hide-left-navbar");
    } else
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.remove("hide-left-navbar");
  }

  openFilterInfomationPopup() {
    this.setState({
      isShowFilterInfomationPopup: true,
    });
  }

  closeFilterInfomationPopup() {
    this.setState({
      isShowFilterInfomationPopup: false,
    });
  }

  manualCloseMaptoolsFilterInfomationPopup = () => {
    this.setState({ isShowFilterInfomationPopup: false });
  };
  setPlanningMap = (_planningModel: any) => {
    this.setState({ planningModel: _planningModel });
  };

  componentDidUpdate(prevProps: Readonly<PlanningMapViewModel.PlanningMapViewProps>, prevState: Readonly<PlanningMapViewModel.PlanningMapViewState>, snapshot?: any): void {
    if( this.props.mapData != prevProps.mapData) {
      this.props.SetDataToggleLayer(ExtracLayerListGroup(this.props.mapData));
    }

    if( this.state.isDoneConstructMap != prevState.isDoneConstructMap) {
      this.setState({isLeftNavbarHide: false});
    }
  }

  componentWillUnmount () {
    this.props.SetDisplayMaptoolPanel(false);
    this.props.SetDisplayInfomationPopup(false);
  }

  render() {
    const isLeftNavbarHide = this.state.isLeftNavbarHide;
    // const isShowMapToolsPanel = this.state.isShowMapToolsPanel;
    return (
      <ToggleBottomProvider>
        <TopRightPanelProvider>
          <AddContentBottomPanelProvider>
            <IsHideFilterBoxProvider>
              <div className="planning-map-view-container">

                {
                  this.props.isDesktopOrLaptop &&
                    <>
                      <Suspense fallback={<div>Page is Loading, please wait...</div>}>
                        <div className="position-absolute map-tools-container">
                          <div className="position-relative">
                            <RenderFilterInfomationPopup
                              listGroupLayer={ExtracLayerListGroup(this.props.mapData)}
                              listLayer={ExtractListLayerArray(this.props.mapData)}
                              
                            />
                          </div>
                          <MapToolsPanel
                            openFIlterPopup={this.openFilterInfomationPopup}
                            map={this.state.mapPlanning}
                          />
                        </div>

                        {
                          this.props.hasLoadedMapData && this.props.mapData.name &&
                          <TopRightPanelView
                            baseMapList={ExtractBasemapList(this.props.mapData)}
                            planningId={this.props.match.params.id}
                            setPlanningModelInMap={this.setPlanningMap}
                          />
                        }

                        {this.props.hasLoadedMapData && this.props.mapData.name && (
                          <LeftMenuView
                            planningId={this.props.match.params.id}
                            // listGroupLayer={ExtracLayerListGroup(this.props.mapData)}
                            isLeftNavbarHide={isLeftNavbarHide}
                            toggleStateIsLeftNavbarHide={() =>
                              this.setState({
                                isLeftNavbarHide: !this.state.isLeftNavbarHide,
                              })
                            }
                            isDoneConstructMap = {this.state.isDoneConstructMap}
                          />
                        )}

                        <GuideUseMap modalHeightStyle={this.state.modalHeightStyle} />
                        <Preview />
                      </Suspense>
                    </>
                }

                {
                  this.props.isTabletOrMobile &&
                  <>
                    <Suspense fallback={<div>Page is Loading, please wait...</div>}>
                      <TopRightPanelMobile
                        baseMapList={ExtractBasemapList(this.props.mapData)}
                        planningId={this.props.match.params.id}
                        setPlanningModelInMap={this.setPlanningMap}
                      />

                      <BottomToggleMobile/>

                      <BottomPanelMobile
                        planningId={this.props.match.params.id}
                      />
                      
                      <RenderFilterInfomationPopupMobile
                        listGroupLayer={ExtracLayerListGroup(this.props.mapData)}
                        listLayer={ExtractListLayerArray(this.props.mapData)}
                      />

                      <GuideUseModalMobile modalHeightStyle={this.state.modalHeightStyle} />
                    </Suspense>
                  </>
                }

                {this.props.hasLoadedMapData && this.props.mapData.name && (
                  <div className={`${!this.state.isLeftNavbarHide ? "open-layers-map-view-container" : "open-layers-map-view-container-100"}`}>
                    <OpenlayersMapView
                      defaultBaseMapUrl={ExtractSelectedBasemap(this.props.mapData)}
                      defaultCenter={ExtractCenterArray(this.props.mapData)}
                      defaultZoom={this.props.mapData.zoom}
                      projection={this.props.mapData.projection}
                      maxZoom={this.props.mapData.max_zoom}
                      minZoom={this.props.mapData.min_zoom}
                      extent={ExtractExtentArray(this.props.mapData)}
                      listLayer={ExtractListLayerArray(this.props.mapData)}
                      planningModel={this.state.planningModel}
                      listGroupLayer={ExtracLayerListGroup(this.props.mapData)}
                      coordinate={this.props.location}
                      setIsDoneConstructMap={(data) => this.setIsDoneConstructMap(data)}
                    />
                  </div>
                )}

                {this.props.hasLoadedMapData && !this.props.mapData.name && (
                  <div
                    className="modal d-block"
                    id="modalNotHaveDataToDisplay"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Dữ liệu không có sẵn để hiển thị
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() =>
                              document
                                .getElementById("modalNotHaveDataToDisplay")
                                ?.classList.remove("d-block")
                            }
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          Lỗi: Bản đồ của dự án chưa được khởi tạo, vui lòng liên hệ với
                          Admin
                        </div>
                        <div className="modal-footer text-center">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() =>
                              document
                                .getElementById("modalNotHaveDataToDisplay")
                                ?.classList.remove("d-block")
                            }
                          >
                            Đóng
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {
                  this.props.isIdNotExist && <div
                  className="modal d-block"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Dữ liệu không có sẵn để hiển thị
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            const invisLink = document.getElementById("invisbile-link-to-SyntheticPlanning");
                            invisLink?.click();
                          }}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        Lỗi: Đồ án không tồn tại, vui lòng liên hệ với Admin
                      </div>
                      <div className="modal-footer text-center">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            const invisLink = document.getElementById("invisbile-link-to-SyntheticPlanning");
                            invisLink?.click();
                          }}
                        >
                          Trở về tổng hợp quy hoạch
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                }

                <Link
                  to={UrlCollect.SyntheticPlanning}
                  style={{display: 'none'}}
                  id="invisbile-link-to-SyntheticPlanning"
                />
              </div>
            </IsHideFilterBoxProvider>
          </AddContentBottomPanelProvider>
        </TopRightPanelProvider>
      </ToggleBottomProvider>
    );
  }
}

const mapStateToProps = (state: any) => ({
  hasLoadedMapData: state.mapData.hasLoadingData,
  isIdNotExist: state.mapData.isIdNotExist,
  mapData: state.mapData.mapData,
  planningRelationShips: state.mapData.planningRelationShips,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      GetMapDetail: MapDataStore.GetMapDetailByPlanningId,
      SetDataToggleLayer: MapToolDataStore.SetDataToggleLayer,
      SetDisplayMaptoolPanel: SetDisplayMaptoolPanel,
      SetDisplayInfomationPopup: SetDisplayInfomationPopup,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PlanningMapViewWithHooks);
