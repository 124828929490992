import React, { useEffect, useState, useRef } from "react";
import * as documentAction from "../../../../redux/store/document/document.store";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCog, faTimes } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";

export const SearchByDistrict = (props) => {
    const { 
        districtSelect,
        setDistrictSelect,
        planningUnitSelect,
        setPlanningUnitSelect,
        approvalAgencySelect,
        setApprovalAgencySelect,
        onSubmit,
        name,
        setName,
        numberOfDecisions,
        setNumberOfDecisions,
        constructionGroup,
        setConstructionGroup,
        yearSearch,
        setYearSearch,
        isLookup
    } = props;
    const advanceSearchContainer = useRef(null);
    const [listDistrict, setListDistrict] = useState([]);
    const [listPlanningUnit, setListPlanningUnit] = useState([]);
    const [listApprovalAgencies, setListApprovalAgencies] = useState([]);
    const [yearStatement, setYearStatement] = useState([]);
    
    const [isOpenSearchAdvance, setIsOpenSearchAdvance] = useState(() => {
        if( !planningUnitSelect && !approvalAgencySelect && !yearSearch && !numberOfDecisions && !constructionGroup) {
            return false;
        }
        else {
            return true
        }
    });

    useEffect(() => {
        GetLookupDistrict();
        GetLookupPlanningUnit();
        GetLookupApprovalAgency();
        GetYearStatement();
    }, []);
    
    useEffect(() => {
        if(!isOpenSearchAdvance) {
            setNumberOfDecisions('');
            setConstructionGroup('');
            setApprovalAgencySelect('');
            setPlanningUnitSelect('');
            setYearSearch('');
        } else {
            const timeoutId = setTimeout(() => {
            advanceSearchContainer.current.style.overflow = 'unset';
            }, 500);
            return () => clearTimeout(timeoutId);
        }
    },[isOpenSearchAdvance])

    const GetLookupDistrict = () => {
        documentAction
            .GetLookUpDistrict()
            .then((res) => {
                setListDistrict(
                    res && res.content && res.content.length > 0
                        ? res.content.map((item) => {
                            return { ...item, label: item.name, value: item.id };
                        })
                        : []
                );
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    const GetLookupPlanningUnit = () => {
        documentAction
            .GetLookUpPlanningUnit()
            .then((res) => {
                setListPlanningUnit(
                    res && res.content && res.content.length > 0
                        ? res.content.map((item) => {
                            return { ...item, label: item.name, value: item.id };
                        })
                        : []
                );
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    const GetLookupApprovalAgency = () => {
        documentAction
            .GetLookUpApprovalAgency()
            .then((res) => {
                setListApprovalAgencies(
                    res && res.content && res.content.length > 0
                        ? res.content.map((item) => {
                            return { ...item, label: item.name, value: item.id };
                        })
                        : []
                );
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    const GetYearStatement = () => {
        return new Promise((resolve, reject) => {
          documentAction
            .GetYearStatement()
            .then((res) => {
              setYearStatement(
                res && res.content && res.content.length > 0
                  ? res.content.map((item) => {
                      return { ...item, label: item, value: item };
                    })
                  : []
              );
              resolve(res);
            })
            .catch((err) => reject(err));
        });
      };

    const handleOnChangeDistrict = (data) => {
        if (data) {
            setDistrictSelect(
                data.map((item) => item.value)
            )
        } else {
            setDistrictSelect([]);
        }
    };

    const handleOnChangePlanningUnit = (data) => {
        if (data) {
            setPlanningUnitSelect(data.value)
        } else {
            setPlanningUnitSelect('');
        }
    };

    const handleOnChangeApprovalAgency = (data) => {
        console.log('handleOnChangeApprovalAgency', data);
        if (data) {
            setApprovalAgencySelect(data.value)
        } else {
            setApprovalAgencySelect('');
        }
    };
    
    const handleOnChangeYears = (data) => {
        if (data) {
            setYearSearch(data.value);
        } else {
            setYearSearch('');
        }
    };

    return (
        <div className="search-planning-container">
            <div>
                <div className="input-group mb-3">
                    <input
                        type="text"
                        name="name"
                        defaultValue={name}
                        onChange={(data) => setName(data.target.value)}
                        //onClick={() => setName('')}
                        className="form-control"
                        placeholder="Tên quy hoạch"
                        aria-label="Tên quy hoạch"
                        aria-describedby="searchFile"
                    />
                </div>

                {listDistrict && listDistrict.length > 0 && (
                    isLookup ? (
                    <Select
                        className="basic-single mb-3"
                        classNamePrefix="select"
                        placeholder="Huyện / Tp"
                        name="district"
                        defaultValue={
                            listDistrict.filter((item)=> {
                                if(typeof(districtSelect) == "string") return null;
                                if(districtSelect.some((id) => id == item.id)) return item;
                            })
                        }
                        isMulti
                        onChange={handleOnChangeDistrict}
                        options={listDistrict}
                        noOptionsMessage={() => "không tồn tại"}
                    />
                    ) : (
                        <Select
                        className="basic-single mb-3"
                        classNamePrefix="select"
                        placeholder="Huyện / Tp"
                        name="district"
                        isMulti
                        onChange={handleOnChangeDistrict}
                        options={listDistrict}
                        noOptionsMessage={() => "không tồn tại"}
                    />
                    )
                )}
            </div>
            <div ref={advanceSearchContainer} className="search-planning-form-content" style={isOpenSearchAdvance ? {maxHeight: "500px"} : {maxHeight:'0', overflow: "hidden"}}>
                {listPlanningUnit && listPlanningUnit.length > 0 && (
                    isLookup ? (
                        <Select
                            className="basic-single mb-3"
                            classNamePrefix="select"
                            placeholder="Đơn vị quy hoạch"
                            name="planningUnit"
                            value={
                                listPlanningUnit.filter((item)=> {
                                        if(planningUnitSelect == item.id) return item;
                                    }
                                )
                            }
                            onChange={handleOnChangePlanningUnit}
                            options={listPlanningUnit}
                            noOptionsMessage={() => "không tồn tại"}
                        />
                        ) : (
                            <Select
                            className="basic-single mb-3"
                            classNamePrefix="select"
                            placeholder="Đơn vị quy hoạch"
                            name="planningUnit"
                            onChange={handleOnChangePlanningUnit}
                            options={listPlanningUnit}
                            noOptionsMessage={() => "không tồn tại"}
                        />
                    )
                )}

                {listApprovalAgencies && listApprovalAgencies.length > 0 && (
                    isLookup ? (
                        <Select
                            className="basic-single mb-3"
                            classNamePrefix="select"
                            placeholder="Đơn vị phê duyệt"
                            name="approvalAgency"
                            value={
                                listApprovalAgencies.filter((item)=> {
                                        if(approvalAgencySelect == item.id) return item;
                                    }
                                )
                            }
                            onChange={handleOnChangeApprovalAgency}
                            options={listApprovalAgencies}
                            noOptionsMessage={() => "không tồn tại"}
                        />
                        ) : (
                            <Select
                            className="basic-single mb-3"
                            classNamePrefix="select"
                            placeholder="Đơn vị phê duyệt"
                            name="approvalAgency"
                            onChange={handleOnChangeApprovalAgency}
                            options={listApprovalAgencies}
                            noOptionsMessage={() => "không tồn tại"}
                        />
                    )
                )}

                {yearStatement && yearStatement.length > 0 && (
                    isLookup ? (
                        <Select
                            className="basic-single mb-3"
                            classNamePrefix="select"
                            placeholder="Thời điểm lập quy hoạch"
                            name="year"
                            value={
                                yearStatement.filter((item)=> {
                                        if(!yearSearch) return null;
                                        if(yearSearch == item.value) return item;
                                    }
                                )
                            }
                            onChange={handleOnChangeYears}
                            options={yearStatement}
                            noOptionsMessage={() => "không tồn tại"}
                        />
                    ) : (
                        <Select
                            className="basic-single mb-3"
                            classNamePrefix="select"
                            placeholder="Thời điểm lập quy hoạch"
                            name="year"
                            onChange={handleOnChangeYears}
                            options={yearStatement}
                            noOptionsMessage={() => "không tồn tại"}
                        />
                    )
                )}

                <div className="input-group mb-3 mt-3">
                    <input
                        type="text"
                        name="numberOfDecisions"
                        value={numberOfDecisions}
                        onChange={(data) => setNumberOfDecisions(data.target.value)}
                        className="form-control"
                        placeholder="Quyết định phê duyệt"
                        aria-label="Quyết định phê duyệt"
                        aria-describedby="numberOfDecisions"
                    />
                </div>

                <div className="input-group mb-3 mt-3">
                    <input
                        type="text"
                        name="constructionGroup"
                        value={constructionGroup}
                        onChange={(data) => setConstructionGroup(data.target.value)}
                        className="form-control"
                        placeholder="Nhóm công trình"
                        aria-label="Nhóm công trình"
                        aria-describedby="constructionGroup"
                    />
                </div>
            </div>
            <div className="row mb-3">
                {
                    !isOpenSearchAdvance ? 
                    (<div className="col-6">
                        <button
                            onClick={() => {setIsOpenSearchAdvance(true);}}
                            type="button"
                            className="btn btn-info w-100"
                            style={{ fontSize: '14px' }}
                        >
                            <FontAwesomeIcon icon={faCog} className="mr-1" />
                            <span>Nâng cao</span>
                        </button>
                    </div>) : 
                    (<div className="col-6">
                        <button
                            onClick={() => {setIsOpenSearchAdvance(false);}}
                            type="button"
                            className="btn btn-danger w-100"
                            style={{ fontSize: '14px' }}
                        >
                            <FontAwesomeIcon icon={faTimes} className="mr-1" />
                            <span>Đóng</span>
                        </button>
                    </div>)
                }
                
                <div className="col-6">
                    <button
                        onClick={onSubmit}
                        type="submit"
                        className="btn btn-primary w-100"
                        style={{ fontSize: '14px' }}
                    >
                        <FontAwesomeIcon icon={faSearch} className="mr-1" />
                        <span>Tìm kiếm</span>
                    </button>
                </div>
            </div>
        </div>
    )
}