import Service from "../../../api/api-service-custom";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetDashboardByReflectionStatus = (data = "") => {
  return service
    .get(ApiUrl.GetDashboardByReflectionStatus, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetDashBoardSatisfactionLevelOverall = (data = "") => {
  return service
    .get(ApiUrl.GetDashBoardSatisfactionLevelOverall, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
