import { Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import { useForm } from "react-hook-form"

import * as appActions from '../../../core/app.store';
import { dateVNFormat, stripHtml } from '../../../utils';

import DeleteIcon from '../../../assets/icon/delete-icon.svg';
import EditIcon from '../../../assets/icon/edit-icon.svg';
import { useDeleteReflect, useGetMyListReflect } from '../hooks';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import './create-reflect.scss';
import ShowNotification from '../../../components/react-notifications/react-notifications';
import { NotificationMessageType } from '../../../utils/configuration';
import { CreateReflect } from '../../../redux/store/reflect/reflect.store'
import CreateOrUpdateReflectView from './create-or-update-reflect.view'
import UrlCollect from '../../../common/url-collect';

function CreateReflectPage() {
  const history = useHistory()

  const handleSuccess = () => {
    history.push(UrlCollect.MyReflect)
  }

  return <CreateOrUpdateReflectView onSuccess={handleSuccess} />;
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateReflectPage);
