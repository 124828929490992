import React from 'react';
import { reflects } from '../../fake-data';
import { useGetReflects } from '../../hooks';
import {
  useGetDashboardByReflectionStatus,
  useGetDashBoardSatisfactionLevelOverall,
} from '../../hooks/dashboard';
import DoughnutChart from '../chart/doughnut-chart.view';
import ReflectHeader from '../header/reflect-header.view';
import { CardHorizontal } from '../reflect-card/reflect-card.view';
import { ReflectAsideSkeleton } from '../skeleton/skeleton.view';
import './aside.scss';

const ReflectAdise = ({ listReflects, isLoading }) => {
  // const { listReflects, isLoading } = useGetReflects();
  const { dashboardByReflectionStatus: byStatus } =
    useGetDashboardByReflectionStatus();
  const { dashboardSatisfactionLevelOverall: byLevel } =
    useGetDashBoardSatisfactionLevelOverall();

  if (isLoading) {
    return <ReflectAsideSkeleton />;
  }

  return (
    <>
          <div className="paper p-4">
        <ReflectHeader
          title='Bản đồ phản ánh'
          urlReadMore=''
          type='section'
          className='mb-4'
        />
        <a href='/ban-do-phan-anh-hien-truong' target="_blank">
          <img
            // src="/assets/image/map-reflect.png"
            src={require("../../../../assets/image/map-reflect.png")}
            alt="Bản đồ phản ánh hiện trường"
            style={{
              'width': '100%',
              'height': '160px',
              'objectFit': 'cover'
            }}>
          </img>
        </a>

      </div>
      <div className='paper p-4 mt-4'>
        <ReflectHeader title='Phản ánh mới' type='section' />
        <div className='reflect-new-content mt-4'>
          {listReflects?.map((item, i) => (
            <div className='mt-2' key={i}>
              <CardHorizontal reflect={item} className='p-0' />
            </div>
          ))}
        </div>
      </div>
      <div className='paper p-4 mt-4'>
        <ReflectHeader
          title='Thống kê kết quả xử lý'
          urlReadMore=''
          type='section'
          className='mb-4'
        />
        <DoughnutChart
          labels={['Tổng mới', 'Đã xử lý', 'Đang xử lý']}
          data={[byStatus?.total, byStatus?.processed, byStatus?.processing]}
        />
      </div>
      <div className='paper p-4 mt-4'>
        <ReflectHeader
          title='Thống kê mức độ hài lòng'
          urlReadMore=''
          type='section'
          className='mb-4'
        />
        <DoughnutChart
          // labels={['Hài lòng', 'Không hài lòng', 'Chấp nhận']}
          labels={['Hài lòng', 'Khác']}
          data={[
            byLevel?.totalSatisfied,
            byLevel?.total - byLevel?.totalSatisfied || 0,
          ]}
        />
      </div>
      {/* <div className="paper p-4 mt-4">
      <ReflectHeader title="Thông tin tuyên truyền" urlReadMore="" type="section" />
      <Grid container spacing={2} className="mt-2 mt-4">
        <Grid item xs={12} className="">
          <img className="d-block w-100 rounded" src="/assets/reflect/info/ytb-1.png" />
        </Grid>
        <Grid item xs={6} className="">
          <img className="d-block w-100 rounded" src="/assets/reflect/info/ytb-2.png" />
        </Grid>
        <Grid item xs={6} className="">
          <img className="d-block w-100 rounded" src="/assets/reflect/info/ytb-3.png" />
        </Grid>
      </Grid>
    </div>
    <div className="mt-4">
      <img className="d-block w-100 rounded shadow" src="/assets/reflect/info/item-1.png" />
      <img className="d-block w-100 rounded shadow mt-3" src="/assets/reflect/info/item-2.png" />
      <img className="d-block w-100 rounded shadow mt-3" src="/assets/reflect/info/item-3.png" />
    </div> */}
    </>
  );
};

export default ReflectAdise;
