import { useState, useEffect } from 'react';
import {
  autoSuggest,
} from '../../../redux/store/reflect/map4d';
import useDebounce from '../../../hooks/useDebounce'

export const useAutoSuggestAddress = (address: string) => {
  const addressDebounce = useDebounce(address, 500)
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const handleGetAddress = async () => {
    setLoading(true)
    try {
      const response = await autoSuggest(address);
      setData(response?.result)
    } catch (error) {
      
    }
    setLoading(false)
  };

  useEffect(() => {
    if (address) {
      handleGetAddress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressDebounce]);

  return {
    data,
    isLoading,
    refresh: handleGetAddress,
  };
};
