import React from 'react';
const IMG_DEFAULT = "../bg-login.png"

export const LeftPopup = (props) => {
  const { img } = props;
  return (
    <div className="col-12 col-lg-6 left-register">
      <img src={img || IMG_DEFAULT} alt="" />
    </div>
  )
}