import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

export const LOGIN = "ACCOUNT/LOGIN";
export const LOGOUT = "ACCOUNT/LOGOUT";

export const login = (user) => ({
  type: LOGIN,
  payload: user,
})
export const logout = () => ({
  type: LOGOUT,
})


const service = new Service();

export const Login = (data) => {
  return service
    .post(ApiUrl.Login, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const Register = (data) => {
  return service
    .post(ApiUrl.Register, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ForgotPassword = (data) => {
  return service
    .post(ApiUrl.ForgotPassword, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ResetPassword = (data) => {
  return service
    .post(ApiUrl.ResetPassword, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const GetUserAccountDetail = () => {
  return service
    .get(ApiUrl.GetUserAccountDetail)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const UpdateUserAccount = (data) => {
  const formData = new FormData();
  data && data.id && formData.append("Id", data.id);
  // data && data.currentPassword && formData.append("CurrentPassword", data.currentPassword);
  // data && data.newPassword && formData.append("NewPassword", data.newPassword);
  data && data.file && formData.append("File", data.file);
  data && data.fullName && formData.append("Fullname", data.fullName);
  data && data.phoneNumber && formData.append("PhoneNumber", data.phoneNumber);
  data && data.email && formData.append("Email", data.email);
  data && data.address && formData.append("Address", data.address);
  return service
    .post(ApiUrl.UpdateUserAccount, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ChangeUserPassword = (data) => {
  const formData = new FormData();
  data && data.userId && formData.append("Id", data.userId);
  data && data.currentPassword && formData.append("CurrentPassword", data.currentPassword);
  data && data.newPassword && formData.append("NewPassword", data.newPassword);
  return service
    .post(ApiUrl.ChangeUserPassword, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

const InitState = {
  isLoggedIn: false,
  user: null,
};

export default function MapDataReducer(state = InitState, action) {
  switch (action.type) {
    case LOGIN:
      return { ...state, isLoggedIn: true, user: action.payload };
    case LOGOUT:
      return { ...state, isLoggedIn: false, user: null };
    default:
      return state;
  }
}
