export function getExtension(filename) {
  if (typeof filename !== 'string') {
    return ''
  }
  const parts = (filename || '')?.split('.');
  return parts?.[parts?.length - 1];
}

export function isImage(filename) {
  const ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'gif':
    case 'bmp':
    case 'png':
      return true;
  }
  return false;
}

export function isVideo(filename) {
  const ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
      return true;
  }
  return false;
}

export function isDocument(filename) {
  const ext = getExtension(filename);
  const type = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf']
  return type?.includes(ext)
}
