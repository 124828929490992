import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ConfirmModal = ({ show, onHide, reflect, onOk, loading }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Xác nhận</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Bạn có chắc muốn xóa phản ánh {reflect?.title} không?
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide} disabled={loading}>
          Đóng
        </Button>
        <Button variant='primary' onClick={onOk} disabled={loading}>
          Đồng ý
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
