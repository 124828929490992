import { useState, useEffect } from 'react';
import {
  GetDashboardByReflectionStatus,
  GetDashBoardSatisfactionLevelOverall,
} from '../../../redux/store/dashboard/dashboard';

export const useGetDashboardByReflectionStatus = () => {
  const [dashboardByReflectionStatus, setDashboardByReflectionStatus] = useState({});
  const [isLoading, setLoading] = useState(true);
  
    const handleGetDashboardByReflectionStatus = async () => {
      setLoading(true);
      const response = await GetDashboardByReflectionStatus();
      setDashboardByReflectionStatus(response?.content || []);
      setLoading(false);
    };

  useEffect(() => {
    handleGetDashboardByReflectionStatus();
  }, []);

  return {
    dashboardByReflectionStatus,
    isLoading,
  };
};

export const useGetDashBoardSatisfactionLevelOverall = () => {
  const [dashboardSatisfactionLevelOverall, setDashboardSatisfactionLevelOverall] = useState({});
  const [isLoading, setLoading] = useState(true);
  
    const handleGetDashBoardSatisfactionLevelOverall = async () => {
      setLoading(true);
      const response = await GetDashBoardSatisfactionLevelOverall();
      setDashboardSatisfactionLevelOverall(response?.content || []);
      setLoading(false);
    };

  useEffect(() => {
    handleGetDashBoardSatisfactionLevelOverall();
  }, []);

  return {
    dashboardSatisfactionLevelOverall,
    isLoading,
  };
};